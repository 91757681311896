export function setCookie(cname, cvalue, exdays) {
  if (exdays){
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  } else {
    document.cookie = cname + "=" + cvalue + ";path=/";    
  }
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function setLocalVariable(name, value){
  if (typeof(Storage) !== "undefined") {
    localStorage.setItem(name, value);
  } else {
    setCookie(name, value);
  }
}

export function getLocalVariable(name){
  if (typeof(Storage) !== "undefined") {
    return localStorage.getItem(name);
  }
  return getCookie(name);
}

export function removeLocalVariable(name){
  if (typeof(Storage) !== "undefined") {
    localStorage.removeItem(name);
  } else {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
}