import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { changeInput } from './Dashboard';

/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';

/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function DashboardList() {
  const projects = useSelector( state => state.dashboard.projects );
  const isAdmin = useSelector( state => state.dashboard.isAdmin );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
      <Grid container spacing={3}>

        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              paddingLeft: '30px',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              flexWrap: 'wrap',
              height: 110,
              cursor: 'pointer',
            }}
            onClick={e =>navigate('/dashboard/settings')}
          >
            <Typography variant="h5">User setting</Typography>
          </Paper>
        </Grid>
        
        { isAdmin &&
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                paddingLeft: '30px',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                flexWrap: 'wrap',
                height: 110,
                cursor: 'pointer',
              }}
              onClick={e =>navigate('/dashboard/admin')}
            >
              <Typography variant="h5">Admin</Typography>
            </Paper>
          </Grid>
        }
        
        { 
          projects.map((p, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Paper
                sx={{
                  paddingLeft: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  flexWrap: 'wrap',
                  height: 110,
                  cursor: 'pointer',
                }}
                onClick={e => {
                  dispatch(changeInput({name: 'activeStep', value: 0}));
                  dispatch(changeInput({ name: 'projId', value: p.id }));
                  navigate(`/dashboard/project/${p.id}/11`);
                }}
              >
                <Typography sx={{flex: '1 0 100%'}} variant="h5">Project:</Typography>
                <Typography variant="h5">{p.projName}</Typography>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
  );
}
