/*global globalParam*/
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
/* import LockOutlinedIcon from '@material-ui/icons/LockOutlined'; */
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Copyright from './Copyright';
import MySnackbar from '../features/MySnackbar';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

const thisSlice = createSlice({
  name: 'signup',
  initialState: { firstName: '', middleInitial: '', lastName: '', email: '', address: '', phone: '', password: '', consentToNews: false }, // to make them accessible by Tos.js
  /* initialState: { firstName: 'Fan', middleInitial: '', lastName: 'Gong', email: 'gongfan99@hotmail.com', address: 'my residence address', phone: '5195895616', password: '123456789', consentToNews: false }, // to make them accessible by Tos.js */
  reducers: {
    changeInput: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    changeConsent: (state, { payload }) => {
      state.consentToNews = payload;
    },
  }
});
export const { changeInput, changeConsent } = thisSlice.actions;
export const reducer = thisSlice.reducer;


/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function SignUp() {
  const { firstName, middleInitial, lastName, email, address, phone, password, consentToNews } = useSelector( state => state.signup );
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleChangeText = e => dispatch(changeInput({ name: e.target.getAttribute('name'), value: e.target.value }));
  const handleConsentCheckBox = e => dispatch(changeConsent(e.target.checked));
  
  const handleSignUp = e => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      if (firstName.length === 0) throw new Error('First name cannot be empty.');
      if (lastName.length === 0) throw new Error('Last name cannot be empty.');
      if (password.length < 8) throw new Error('Password should be at least 8 characters');
      
      const emailChecker = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!emailChecker.test(email)) throw new Error('Invalid email.');
      
      const phoneChecker = /^\d{10,11}$/;
      const _phone = phone.replace(/[^\d]/g, '');
      if (!phoneChecker.test(_phone)) throw new Error('Invalid phone number.');
      dispatch(changeInput({ name: 'phone', value: _phone }));
    } catch(err){
      setErrorMsg(err.message);
      return;      
    } finally {
      e.target.disabled = false;
    }
    
    setTimeout(() => {navigate('/tos')}, 50); // to allow time for phone update
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar src={globalParam.logo100} sx={{ m: 1, bgcolor: 'secondary.main', cursor: 'pointer' }} onClick={e => navigate('/')}>
          {/*<LockOutlinedIcon />*/}
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                autoComplete="fname"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={firstName}
                onChange={handleChangeText}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoComplete="mname"
                name="middleInitial"
                fullWidth
                id="middleInitial"
                label="Middle"
                value={middleInitial}
                onChange={handleChangeText}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={handleChangeText}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={handleChangeText}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="address"
                label="Residence Address"
                name="address"
                value={address}
                onChange={handleChangeText}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="phone"
                label="Cell Phone"
                name="phone"
                value={phone}
                onChange={handleChangeText}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={handleChangeText}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" checked={consentToNews} onChange={handleConsentCheckBox} />}
                label="I want to receive news about Disrobing Room."
              />
            </Grid>
          </Grid>
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleSignUp} >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/signin">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>          
          <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
