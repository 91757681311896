/*global firebase*/
import React from 'react';
import { BrowserRouter as Router, Navigate, useRoutes } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

// Pages
import SearchReport from './pages/SearchReport';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Tos from './pages/Tos';
import Contact from './pages/Contact';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import DashboardList from './pages/DashboardList';
import Settings from './pages/Settings';
import Admin from './pages/Admin';
import ReportList from './pages/ReportList';
import ReportEdit from './pages/ReportEdit';
import ProjectPayment from './pages/ProjectPayment';
import ProjectCreate from './pages/ProjectCreate';
import ProjectStepWrap from './pages/ProjectStepWrap';
import ProjectStep10 from './pages/ProjectStep10';
import ProjectStep20 from './pages/ProjectStep20';
import ProjectStep30 from './pages/ProjectStep30';
import ProjectStep40 from './pages/ProjectStep40';
import ProjectStep50 from './pages/ProjectStep50';
import ProjectStep60 from './pages/ProjectStep60';
import ProjectStep70 from './pages/ProjectStep70';
import ProjectStep80 from './pages/ProjectStep80';
import ProjectStep90 from './pages/ProjectStep90';
import ProjectStep95 from './pages/ProjectStep95';
import ProjectFinish from './pages/ProjectFinish';
import NotFound from './pages/NotFound';
import Try from './pages/Try';
import { store } from './app/store';
import { changeErrorMsg as changeSignInErrorMsg } from './pages/SignIn';


/********************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

const thisSlice = createSlice({
  name: 'app',
  initialState: { isSignedIn: false },
  reducers: {
    changeAuthStatus: (state, { payload }) => {
      state.isSignedIn = payload;
    },
  }
});
export const { changeAuthStatus } = thisSlice.actions;
export const reducer = thisSlice.reducer;


/*****************************************************************************************
* firebase authentication works this way: onAuthStateChanged
*****************************************************************************************/
function isUnverifiedEmailLogin(){
  const user = firebase.auth().currentUser;
  return user?.providerData[0]?.providerId === firebase.auth.EmailAuthProvider.PROVIDER_ID && !user.emailVerified;
}

firebase.auth().onAuthStateChanged(async user => {
  const { dispatch } = store;
  try {
    if (user) { // User is signed in!
      if (isUnverifiedEmailLogin()){
        // firebase allows user to sign in even when the email is not verified; we design a workaround to prevent this.
        // when the user sign in from the /signin page, we check the email-verification status here and logout user if not verified.
        if (window.location.pathname === '/signin'){
          dispatch(changeSignInErrorMsg('The email is not verified. Please check your email inbox.'));
          firebase.auth().signOut();
        }
      } else {
        dispatch(changeAuthStatus(true));
      }
    } else { // User is signed out!
      dispatch(changeAuthStatus(false));
    }
  } catch(err){
    console.log(err.message);
  }
});


/*****************************************************************************************
* component definition
*****************************************************************************************/
function App() {
  const isSignedIn = useSelector( state => state.app.isSignedIn );
  
  let element = useRoutes([
    //{ path: '/', element: !isSignedIn ? <Navigate to="/signin" /> : <Navigate to="/dashboard" /> },
    { path: '/', element: <SearchReport /> },
    { path: 'signin', element: !isSignedIn ? <SignIn /> : <Navigate to="/dashboard" /> },
    { path: 'signup', element: !isSignedIn ? <SignUp /> : <Navigate to="/dashboard" /> },
    { path: 'tos', element: !isSignedIn ? <Tos /> : <Navigate to="/dashboard" /> },
    { path: 'contact', element: <Contact /> },
    { path: 'try', element: <Try /> },
    { path: 'resetpassword', element: !isSignedIn ? <ResetPassword /> : <Navigate to="/dashboard" /> },
    /* { path: 'dashboard', element: isSignedIn ? <Dashboard /> : <Navigate to="/signin" /> }, */
    { path: 'dashboard',
      element: <Dashboard />,
      children: [
        { path: '/', element: <Navigate to="list" /> },
        /* { path: 'projectlist', element: <ProjectList /> }, */
        { path: 'settings', element: <Settings /> },
        { path: 'list', element: <DashboardList /> },
        { path: 'admin', element: <Admin /> },
        { path: 'project/payment', element: <ProjectPayment /> },
        { path: 'project/create', element: <ProjectCreate /> },
        { path: 'project/finish', element: <ProjectFinish /> },
        { path: 'project/:projId',
          element: <ProjectStepWrap />,
          children: [
            { path: '11', element: <ProjectStep10 /> },
            { path: '12', element: <ProjectStep20 /> },
            { path: '13', element: <ProjectStep30 /> },
            { path: '14', element: <ProjectStep40 /> },
            { path: '15', element: <ProjectStep50 /> },
            { path: '16', element: <ProjectStep60 /> },
            { path: '17', element: <ProjectStep70 /> },
            { path: '18', element: <ProjectStep80 /> },
            { path: '19', element: <ProjectStep90 /> },
            { path: '20', element: <ProjectStep95 /> },
          ]
        },
        { path: 'report/list', element: <ReportList /> },
        { path: 'report/:reportId', element: <ReportEdit /> },
/*         { path: ':id', element: <Invoice /> },
        { path: 'sent', element: <SentInvoices /> } */
      ]
    },
    { path: '*', element: <NotFound /> },
  ]);

  // The returned element will render the entire element
  // hierarchy with all the appropriate context it needs
  return element;
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
