/*global db, isMobile, delay, globalParam*/
import React, { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import MyStepper from '../features/MyStepper';
import { changeInput } from '../pages/Dashboard';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch, batch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
function ChecklistWrap() {
  const { activeStep, userConfirm, judgePhotoDocUrl } = useSelector( state => state.dashboard );
  const [imageSrc, setImageSrc] = useState(globalParam.logo100);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!judgePhotoDocUrl) return setImageSrc(globalParam.logo100);
      try {
/*         const pathReference = storage.ref(`users/${firebase.auth().currentUser.uid}/${judgePhotoDocId}`);
        const url = await pathReference.getDownloadURL(); */
        const url = judgePhotoDocUrl;
        setImageSrc(url);
      } catch(error) {
      }
    })();
  }, [judgePhotoDocUrl]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
      <Grid container spacing={2} sx={{marginBottom: '6px'}}>
        <Grid item xs={12} sm={12}>
          <Typography component="h2" variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
            { imageSrc && <Avatar alt="Judge" src={imageSrc} sx={{width: '30px', height: '30px', marginRight: '15px'}}/> }
            Project Checklist
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          {!isMobile && 
          <MyStepper 
            activeStep={activeStep} 
            setActiveStep={i => dispatch(changeInput({ name: 'activeStep', value: i }))} 
            userConfirm={userConfirm}
            getLinkPath={i => `${i+11}`} 
          />}
        </Grid>
      </Grid>
  );
}

export default function ProjectStepWrap() {
  const { states } = useSelector( state => state.dashboard );
  let { projId } = useParams();
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const projSnapshot = await db.collection('projects').doc(projId).get();
      const project = projSnapshot.data();
      
      batch(() => { // batch is necessary for React ver 17 or earlier; React ver 18 will automatically batch dispatch
        const keys = [
          'projName', 'reasonRemoveJudge', 'caseNumber', 'courthouseAddress', 'courthouseName', 'judgeName', 'courthouseCity', 'courthouseZip', 'judgePhotoDocId', 'judgePhotoDocUrl',
          'noticeOfIntentionDocId', 'noticeOfIntentionDocName', 'noticeOfIntentionDocUrl',
          'serverFirstName', 'serverMiddleInitial', 'serverLastName', 'serverAddress', 'serverCity', 'serverState', 'serverZip', 'methodOfService', 'presJudgeName', 'presJudgeState', 'presJudgeCounty', 'presJudgeCourthouseName', 'presJudgeCourthouseAddress', 'cityOfProof', 'stateOfProof',
          'proofOfServiceDocId', 'proofOfServiceDocName', 'proofOfServiceDocUrl',
          'reasonRecuseJudge', 'addressVoterRegistrar', 'affidavitToDisqualifyDocId', 'affidavitToDisqualifyDocName', 'affidavitToDisqualifyDocUrl',
          'nameOfTopParty', 'designationOfTopParty', 'nameOfBottomParty', 'designationOfBottomParty', 'attorneyName', 'attorneyAddress', 'attorneyCity', 'attorneyState', 'attorneyZip',
        ];
        for (const k of keys){
          dispatch(changeInput({ name: k, value: project[k] ?? '' }));
        }
        dispatch(changeInput({ name: 'userConfirm', value: project['userConfirm'] ?? [] }));
        dispatch(changeInput({ name: 'state', value: project['state'] || states[0] }));
        dispatch(changeInput({ name: 'county', value: project['county'] ?? '' }));
        
        dispatch(changeInput({ name: 'methodOfService', value: project['methodOfService'] ?? 'Personal Service' }));
        dispatch(changeInput({ name: 'isPresidingJudge', value: project['isPresidingJudge'] ?? false }));
        dispatch(changeInput({ name: 'isJudgeServed', value: project['isJudgeServed'] ?? false }));
        dispatch(changeInput({ name: 'dateOfService', value: project['dateOfService'] ?? null }));
        dispatch(changeInput({ name: 'timeOfService', value: project['timeOfService'] ?? null }));
        dispatch(changeInput({ name: 'dateOfProof', value: project['dateOfProof'] ?? null }));
        
        dispatch(changeInput({ name: 'isNoticeOfIntentionFiled', value: project['isNoticeOfIntentionFiled'] ?? false }));
        dispatch(changeInput({ name: 'dateNoticeOfIntentionFiled', value: project['dateNoticeOfIntentionFiled'] ?? null }));
        dispatch(changeInput({ name: 'confirmDateNoticeOfIntentionFiled', value: project['confirmDateNoticeOfIntentionFiled'] ?? false }));
        
        dispatch(changeInput({ name: 'isAttorney', value: project['isAttorney'] ?? false }));
        
        dispatch(changeInput({ name: 'projId', value: projId })); // to handle navigation to this page through direct url
      });

      await delay(10); // give some time for the above dispatches to finish before rendering Outlet
      setDataLoaded(true);
    })();
  }, [projId]); // eslint-disable-line react-hooks/exhaustive-deps  
  
  return (
    <>
      <ChecklistWrap />
      { dataLoaded && <Outlet /> }
      {/*<Outlet />*/}
    </>
  );
}
