import React from 'react';
import { useNavigate } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import './MyStepper.css';

const hintList = [
  'DRAFT THE NOTICE OF INTENTION',
  'GATHER SIGNATURES ON NOTICE OF INTENTION',
  'MAKE PAPER COPIES OF NOTICE OF INTENTION WITH SIGNATURES',
  'SERVE THE JUDGE',
  'URGENT REMINDER OF FILING DEADLINE',
  'COMPLETE PROOF OF SERVICE',
  'FILE NOTICE OF INTENTION AND PROOF OF SERVICE',
  'PUBLISH NOTICE OF INTENT',
  'DRAFT AFFIDAVIT TO DISQUALIFY JUDGE',
  'FILE AFFIDAVIT TO DISQUALIFY JUDGE',
]


export default function MyStepper({ activeStep, setActiveStep, userConfirm, getLinkPath }) {
  const navigate = useNavigate();
  return (
    <div className="my-stepper-container">
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {hintList.map((hint, stepIndex) => {
          const done = !!userConfirm[stepIndex];
          const handleClick = e => {
            setActiveStep(stepIndex);
            navigate(getLinkPath(stepIndex));
          }
          return (
            <Tooltip
              title={
                <>
                  <Typography color="inherit">{`Step ${stepIndex+1}`}</Typography>
                  <em>{hintList[stepIndex]}</em>
                </>
              }
              key={stepIndex}
            >
              <Step>
                <StepButton onClick={handleClick} >
                </StepButton>
                <div className="my-stepper-check" onClick={handleClick}>{done? '✔' : ' '}</div>
              </Step>
            </Tooltip>
          );
        })}
      </Stepper>
    </div>
  );
}