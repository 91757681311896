/*global isMobile*/
import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { callServerPost } from '../features/CallServer';
import Loading from '../features/Loading';
import MySnackbar from '../features/MySnackbar';
import { changeInput } from './Dashboard';


/*****************************************************************************************
* set up redux for this component
* the reason to make errorMsg in the store is to let it be accessed by
* firebase.auth().onAuthStateChanged in the App.js
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

const thisSlice = createSlice({
  name: 'signin',
  initialState: { errorMsg: '' },
  reducers: {
    changeErrorMsg: (state, { payload }) => {
      state.errorMsg = payload;
    },
  }
});
export const { changeErrorMsg } = thisSlice.actions;
export const reducer = thisSlice.reducer;

/*****************************************************************************************
* component definition
*****************************************************************************************/
function TimeChoices({ timeForText, setTimeForText, show }) {
  if (!show) return null;
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let step = 60;
  let choiceValues = [], choiceNames = [];
  const d2 = n => (n + 100).toString().slice(-2); // e.g. 2 -> '02', 45 -> '45'
  for (let i = 0; i < 24*60; i += step){
    choiceValues.push(i); // in minutes from midnight
    let hour = Math.floor(i/60);
    let minute = i - hour * 60;
    if (hour === 0){
      choiceNames.push(`12:${d2(minute)} AM ${browserTimeZone}`);
    } else if (hour < 12) {
      choiceNames.push(`${d2(hour)}:${d2(minute)} AM ${browserTimeZone}`);      
    } else if (hour === 12) {
      choiceNames.push(`12:${d2(minute)} PM ${browserTimeZone}`);      
    } else {
      choiceNames.push(`${d2(hour-12)}:${d2(minute)} PM ${browserTimeZone}`);      
    }
  }
  return (
    <Select
      value={timeForText}
      onChange={e => setTimeForText(Number(e.target.value))}
      native={isMobile}
      inputProps={{ 'aria-label': 'selection of time' }}
      sx={{ mb: 1 }}
    >
      { 
        choiceValues.map( (c, i) => isMobile? <option key={i} value={c}>{choiceNames[i]}</option> : <MenuItem key={i} value={c}>{choiceNames[i]}</MenuItem> )
      }
    </Select>
  )
}

export default function Settings() {
/*   const [firstName, setFirstName] = useState('');
  const [middleInitial, setMiddleInitial] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [consentToDailySms, setConsentToDailySms] = useState(false);
  const [consentToDailyEmail, setConsentToDailyEmail] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(true);
  const [timeForText, setTimeForText] = useState(600); // in minutes from midnight
  const [timeForEmail, setTimeForEmail] = useState(600); // in minutes from midnight */
  const {firstName, middleInitial, lastName, address, phone, consentToDailySms, consentToDailyEmail, isPhoneVerified, timeForText, timeForEmail, localTimeZone} = useSelector(state => state.dashboard);
  const dispatch = useDispatch();
  const [phoneCode, setPhoneCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
/*   const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [localTimeZone, setLocalTimeZone] = useState(browserTimeZone); */
  let phoneInRecord = useRef();
  let isPhoneInRecordVerified = useRef();
  
  useEffect(() => {
    (async () => {
/*       const user = firebase.auth().currentUser;
      const docRef = await db.collection('users').doc(user.uid).get();
      const data = docRef.data();
      setFirstName(data.firstName ?? '');
      setMiddleInitial(data.middleInitial ?? '');
      setLastName(data.lastName ?? '');
      setAddress(data.address ?? '');
      setPhone(data.phone ?? '');
      setConsentToDailySms(!!data.consentToDailySms);
      setConsentToDailyEmail(!!data.consentToDailyEmail);
      setIsPhoneVerified(!!data.isPhoneVerified);
      setTimeForText(data.timeForText ? (data.timeForText - minuteDiffBetweenTimeZone(data.localTimeZone, browserTimeZone)) : 600);
      setTimeForEmail(data.timeForEmail ? (data.timeForEmail - minuteDiffBetweenTimeZone(data.localTimeZone, browserTimeZone)) : 600);
      setLocalTimeZone(browserTimeZone);
      phoneInRecord.current = data.phone;
      isPhoneInRecordVerified.current = !!data.isPhoneVerified; */
      phoneInRecord.current = phone;
      isPhoneInRecordVerified.current = !!isPhoneVerified;
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // when user changes the phone to a different number, it invalidates the verification.
  useEffect(() => {
    if (phoneInRecord.current){ // wait for the fetch to finish before this takes effect
      /* setIsPhoneVerified(isPhoneInRecordVerified.current && phoneInRecord.current === phone.replace(/[^\d]/g, '')); */
      dispatch(changeInput({name: 'isPhoneVerified', value: isPhoneInRecordVerified.current && phoneInRecord.current === phone.replace(/[^\d]/g, '')}));
    }
  }, [phone]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleDailySmsCheckBox = e => {
    if (!isPhoneVerified){
      setErrorMsg('Your phone needs to be verified first.');
      return;
    }
    dispatch(changeInput({name: 'consentToDailySms', value: e.target.checked}));
  }

  const handleOpenVerifyDialog = async e => {
    e.preventDefault();
    e.target.disabled = true;
    setLoading(true);
    setSuccessMsg('');
    setErrorMsg('');
    try {
      await callServerPost('create-phone-code', {phone: phone.replace(/[^\d]/g, '')});
      setDialogOpen(true);
      setPhoneCode('');
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      setLoading(false);
    }
  };

  const handleVerifyPhoneCode = async e => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      const res = await callServerPost('verify-phone-code', {phone: phone.replace(/[^\d]/g, ''), phoneCode});
      dispatch(changeInput({name: 'phone', value: res.phone}));
      dispatch(changeInput({name: 'isPhoneVerified', value: res.isPhoneVerified}));
/*       setIsPhoneVerified(res.isPhoneVerified);
      setPhone(res.phone); */
      isPhoneInRecordVerified.current = res.isPhoneVerified;
      phoneInRecord.current = res.phone;
      setErrorMsg('');
      setSuccessMsg('Phone number successfully verified');
    } catch (err) {
      setSuccessMsg('');
      setErrorMsg(err.message);
    } finally {
      setDialogOpen(false);
      e.target.disabled = false;
    }
  };

  const handleSave = async e => {
    e.preventDefault();
    e.target.disabled = true;
    setLoadingSave(true);
    setSuccessMsg('');
    setErrorMsg('');
    try {
      const phoneChecker = /^\d{10,11}$/;
      const _phone = phone.replace(/[^\d]/g, '');
      if (!phoneChecker.test(_phone)) throw new Error('Invalid phone number.');
      
      const res = await callServerPost('save-user-settings', {
        firstName, middleInitial, lastName, address, phone: _phone, consentToDailySms, consentToDailyEmail, timeForText, timeForEmail, localTimeZone,
      });
      dispatch(changeInput({name: 'phone', value: res.phone}));
      dispatch(changeInput({name: 'isPhoneVerified', value: res.isPhoneVerified}));
/*       setIsPhoneVerified(res.isPhoneVerified);
      setPhone(res.phone); */
      isPhoneInRecordVerified.current = res.isPhoneVerified;
      phoneInRecord.current = res.phone;

      setSuccessMsg('Successfully saved');
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      setLoadingSave(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <TextField
          autoComplete="fname"
          name="firstName"
          required
          fullWidth
          id="firstName"
          label="First Name"
          value={firstName}
          onChange={e => dispatch(changeInput({name: 'firstName', value: e.target.value}))}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          autoComplete="mname"
          name="middleInitial"
          fullWidth
          id="middleInitial"
          label="Middle"
          value={middleInitial}
          onChange={e => dispatch(changeInput({name: 'middleInitial', value: e.target.value}))}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          autoComplete="lname"
          value={lastName}
          onChange={e => dispatch(changeInput({name: 'lastName', value: e.target.value}))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="address"
          label="Residence Address"
          name="address"
          value={address}
          onChange={e => dispatch(changeInput({name: 'address', value: e.target.value}))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="phone"
          label="Cell Phone"
          name="phone"
          value={phone}
          onChange={e => dispatch(changeInput({name: 'phone', value: e.target.value}))}
        />
      </Grid>
      <Grid item xs={12}>
      { !isPhoneVerified && 
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '100%', sm: '30%'} }} onClick={handleOpenVerifyDialog}>
          {loading? <Loading /> : 'Verify Phone Number'}
        </Button>
      }
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormControlLabel
          control={<Checkbox value="allowDailySMS" color="primary" checked={consentToDailySms} onChange={handleDailySmsCheckBox} />}
          label="Get daily reminders by text?"
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TimeChoices timeForText={timeForText} setTimeForText={v => dispatch(changeInput({name: 'timeForText', value: v}))} show={consentToDailySms} />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormControlLabel
          control={<Checkbox value="allowDailyEmail" color="primary" checked={consentToDailyEmail} onChange={e => dispatch(changeInput({name: 'consentToDailyEmail', value: e.target.checked}))} />}
          label="Get daily reminders by email?"
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TimeChoices timeForText={timeForEmail} setTimeForText={v => dispatch(changeInput({name: 'timeForEmail', value: v}))} show={consentToDailyEmail} />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '30%', sm: '10%'} }} onClick={handleSave}>
          {loadingSave? <Loading /> : 'Save'}
        </Button>
      </Grid>
      <Grid item>
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />      
      </Grid>
      
      <Dialog open={dialogOpen} onClose={e => setDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Verify phone</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A number has been sent to your phone through short message. Please enter the number below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="verify-number"
            label="6 digits number"
            type="text"
            fullWidth
            value={phoneCode}
            onChange={e => setPhoneCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleVerifyPhoneCode} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
