/*global */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { callServerGet, callServerPost } from '../features/CallServer';
import Loading from '../features/Loading';
import MySnackbar from '../features/MySnackbar';


/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function ProjectPayment() {
  const [projPrice, setProjPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  
  useEffect(() => {
    (async () => {
      const { projPrice: p } = await callServerGet('get-project-price');
      setProjPrice(p);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handlePay = async e => { // will redirect to stripe webpage by the /create-checkout-session endpoint
    setLoading(true);
    e.target.disabled = true;
    try {
      const { redirect_url } = await callServerPost('create-checkout-session'); // the price and currency are defined in the server side; so we do not need to post any data here
      window.location.href = redirect_url;
    } catch (err) {
      console.log(err);
      setErrorMsg(err.message);
    } finally {
      setLoading(false);
      e.target.disabled = false;
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography component="h2" variant="h6">
          {`Each project costs $${projPrice}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" onClick={handlePay} sx={{ mt: 0, mb: 0, minWidth: {xs: '40%', sm: '40%'} }} >
          {loading? <Loading /> : 'Pay'}
        </Button>
      </Grid>
      <Grid item>
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />      
      </Grid>
      
    </Grid>
  );
}
