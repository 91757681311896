/*global */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // load on demand
import timezone from 'dayjs/plugin/timezone' // load on demand
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { ProjSaveButton, PdfDownloadButton } from '../features/ProjButton';
import { changeInput } from '../pages/Dashboard';

/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ProjectStep80() {
  const { projId, userConfirm, county, judgeName, state } = useSelector( state => state.dashboard );
  /* const [dateValidSig, setDateValidSig] = useState(''); */
  const [pdfFileName, setPdfFileName] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStep = 7;

  useEffect(() => {
    dispatch(changeInput({name: 'activeStep', value: currentStep})); // to handle navigation to this page through direct url
  }, []); // eslint-disable-line react-hooks/exhaustive-deps  
  
/*   useEffect(() => {    
    if (dateNoticeOfIntentionFiled){
      const t = dayjs(dateNoticeOfIntentionFiled).tz(localTimeZone).add(30, 'day');
      setDateValidSig(t.format('MMMM DD, YYYY'));
    }
  }, [dateNoticeOfIntentionFiled, localTimeZone]); // eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {    
    if (judgeName) setPdfFileName(`Request-for-Affidavit-of-Publication-regarding-Hon-${judgeName.replace(/[^A-Za-z0-9]{1,}/g, '-')}.pdf`);
  }, [judgeName]); // eslint-disable-line react-hooks/exhaustive-deps  
  
  const handleChangeCheckBox = e => {
    let _userConfirm = [...userConfirm];
    _userConfirm[currentStep] = e.target.checked;
    dispatch(changeInput({ name: 'userConfirm', value: _userConfirm }));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="subtitle1">
          Step 8: PUBLISH NOTICE OF INTENT
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          You are required to publish  - at your expense - the Notice of Intention in a newspaper of general circulation in {county} County. This will be the same kind of little newspaper that publishes DBA business notices, probate notices, and things like that.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Do an internet search for 'newspapers that publish legal notices in {county} County, {state} lowest price'.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Prices for publishing legal notices vary. You should be able to publish this for under $100, or $200 at the most. Some newspapers will try to charge you a lot more. Shop around. Negotiate.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          <Link color="inherit" href={`https://duckduckgo.com/?q=newspapers+that+publish+legal+notices+in+${county}+County+${state}+lowest+price+`} target="_blank">Search for newspapers</Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          You must send the Affidavit of Proof of Publication to the newspaper and have them fill it out and return it to you.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PdfDownloadButton 
          projId={projId} 
          docType="Request-for-Affidavit-of-Publication"
          downloadFileName={pdfFileName}
          buttonText="Download Request for Affidavit of Publication"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Remember to publish the Notice of Intention in a newspaper of general circulation. Don’t forget to send the newspaper the Affidavit of Proof of Publication.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Checkbox value="userConfirm" color="primary" checked={userConfirm[currentStep] ?? false}
          onChange={handleChangeCheckBox} />}
          label="I confirm I have published the Notice of Intent in the Newspaper."
          name="userConfirm"
        />
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep - 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 10}`);
          }}
        >
          Prev
        </Button>
      </Grid>
      <Grid item xs={4} sm={4}>
        <ProjSaveButton />
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep + 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 12}`);
          }}
        >
          Next
        </Button>
      </Grid>
      
    </Grid>
  );
}
