/*global */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { callServerPost } from '../features/CallServer';
import Loading from '../features/Loading';
import MySnackbar from '../features/MySnackbar';
import { changeInput } from '../pages/Dashboard';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function ProjectStep20() {
  const { projId, userConfirm } = useSelector( state => state.dashboard );
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStep = 1;
  
  useEffect(() => {
    dispatch(changeInput({name: 'activeStep', value: currentStep})); // to handle navigation to this page through direct url
  }, []); // eslint-disable-line react-hooks/exhaustive-deps  
  
  const handleChangeCheckBox = e => {
    let _userConfirm = [...userConfirm];
    _userConfirm[currentStep] = e.target.checked;
    dispatch(changeInput({ name: 'userConfirm', value: _userConfirm }));
  }
  
  const handleSave = async e => {
    e.preventDefault();
    e.target.disabled = true;
    setLoadingSave(true);
    try {
      await callServerPost('save-project', {
        projId, userConfirm,
      });
      setSuccessMsg('Successfully saved');
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      setLoadingSave(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="subtitle1">
          Step 2: GATHER SIGNATURES ON NOTICE OF INTENTION
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          You must print copies of the Notice of Intention, and get at least 10 valid signatures of registered voters. We suggest getting 30 signatures to be safe.
        </Typography>
        <Typography component="h3" variant="body1">
          If you want to hire a person to gather signatures, we suggest a simple ad on&nbsp;<Link color="inherit" href="https://www.craigslist.org/about/help/faq-job" target="_blank">Craigslist</Link>.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Checkbox value="userConfirm" color="primary" checked={userConfirm[currentStep] ?? false}
          onChange={handleChangeCheckBox} />}
          label="I confirm I have finished gathering signatures."
          name="userConfirm"
        />
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep - 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 10}`);
          }}
        >
          Prev
        </Button>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={handleSave}>
          {loadingSave? <Loading /> : 'Save'}
        </Button>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep + 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 12}`);
          }}
        >
          Next
        </Button>
      </Grid>
      <Grid item>
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />      
      </Grid>
      
    </Grid>
  );
}
