/*global */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // load on demand
import timezone from 'dayjs/plugin/timezone' // load on demand
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ProjSaveButton, PdfDownloadButton, PdfDownloadUpload } from '../features/ProjButton';
import { changeInput } from '../pages/Dashboard';

/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ProjectStep60() {
  const { localTimeZone, projId, judgeName, userConfirm, serverFirstName, serverMiddleInitial, serverLastName, dateOfService, methodOfService } = useSelector( state => state.dashboard );
  const [filingDeadline, setFilingDeadline] = useState('');
  const [pdfFileName, setPdfFileName] = useState('');
  const [serverFullName, setServerFullName] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStep = 5;

  useEffect(() => {
    dispatch(changeInput({name: 'activeStep', value: currentStep})); // to handle navigation to this page through direct url
  }, []); // eslint-disable-line react-hooks/exhaustive-deps  
  
  useEffect(() => {    
    if (dateOfService){
      const t = dayjs(dateOfService).tz(localTimeZone).add(7, 'day');
      setFilingDeadline(t.format('MMMM DD, YYYY'));
    }
  }, [dateOfService, localTimeZone]); // eslint-disable-line react-hooks/exhaustive-deps   
  
  useEffect(() => {    
    if (serverFirstName || serverLastName){
      const _serverFullName = (serverMiddleInitial.length === 0)? `${serverFirstName} ${serverLastName}` : `${serverFirstName} ${serverMiddleInitial} ${serverLastName}`;
      setServerFullName(_serverFullName);
    }
  }, [serverFirstName, serverMiddleInitial, serverLastName]); // eslint-disable-line react-hooks/exhaustive-deps   
  
  useEffect(() => {    
    if (judgeName) setPdfFileName(`Proof-of-Service-${judgeName.replace(/[^A-Za-z0-9]{1,}/g, '-')}.pdf`);
  }, [judgeName]); // eslint-disable-line react-hooks/exhaustive-deps  
  
  const handleChangeCheckBox = e => {
    let _userConfirm = [...userConfirm];
    _userConfirm[currentStep] = e.target.checked;
    dispatch(changeInput({ name: 'userConfirm', value: _userConfirm }));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="subtitle1">
          Step 6: COMPLETE PROOF OF SERVICE
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PdfDownloadButton 
          projId={projId} 
          docType={`Proof-of-Service-${(methodOfService === 'Personal Service')? 'Personal' : 'Mail'}`}
          downloadFileName={pdfFileName}
          buttonText="Download Proof of Service"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Print 1 copy of <b>{pdfFileName}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Have <b>{serverFullName}</b> sign the Proof of Service and return it to you. Don’t wait. Remember - your papers must be filed on or before <b>{filingDeadline}</b>.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Scan Proof of Service to PDF. Print a paper copy of the completed Proof of Service.
        </Typography>
      </Grid>
      <PdfDownloadUpload 
        description="Upload the completed Proof of Service:" 
        fileName={`Proof-of-Service-completed-${judgeName.replace(/[^A-Za-z0-9]{1,}/g, '-')}.pdf`}
        keyDocId="proofOfServiceDocId"
        keyDocName="proofOfServiceDocName"
        keyDocUrl="proofOfServiceDocUrl"
      />
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Remember - your Notice of Intention and Proof of Service must be filed on or before <b>{filingDeadline}</b>.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Checkbox value="userConfirm" color="primary" checked={userConfirm[currentStep] ?? false}
          onChange={handleChangeCheckBox} />}
          label="I confirm I have completed the Proof of Service."
          name="userConfirm"
        />
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep - 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 10}`);
          }}
        >
          Prev
        </Button>
      </Grid>
      <Grid item xs={4} sm={4}>
        <ProjSaveButton />
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep + 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 12}`);
          }}
        >
          Next
        </Button>
      </Grid>
      
    </Grid>
  );
}
