let domainUrlBackend = 'http://192.168.0.33:8080';
let domainUrlFrontend = 'http://192.168.0.33:3000';
if (window.location.protocol === 'https:'){
  //domainUrlBackend = 'https://disrobing-room-backend-6m6efoykbq-ue.a.run.app';
  domainUrlBackend = 'https://dr-report.pmjmp.org';
  domainUrlFrontend = 'https://dr.pmjmp.org';
}
export { domainUrlBackend, domainUrlFrontend };

export const reportWebSite = 'https://www.pmjmp.org';

export const states = [
  {
    "name": "Alabama",
    "abbreviation": "AL",
    "service": true
  },
  {
    "name": "Alaska",
    "abbreviation": "AK",
    "service": true
  },
  {
    "name": "American Samoa",
    "abbreviation": "AS",
    "service": true
  },
  {
    "name": "Arizona",
    "abbreviation": "AZ",
    "service": false
  },
  {
    "name": "Arkansas",
    "abbreviation": "AR",
    "service": false
  },
  {
    "name": "California",
    "abbreviation": "CA",
    "service": false
  },
  {
    "name": "Colorado",
    "abbreviation": "CO",
    "service": false
  },
  {
    "name": "Connecticut",
    "abbreviation": "CT",
    "service": false
  },
  {
    "name": "Delaware",
    "abbreviation": "DE",
    "service": false
  },
  {
    "name": "District Of Columbia",
    "abbreviation": "DC",
    "service": false
  },
  {
    "name": "Federated States Of Micronesia",
    "abbreviation": "FM",
    "service": false
  },
  {
    "name": "Florida",
    "abbreviation": "FL",
    "service": false
  },
  {
    "name": "Georgia",
    "abbreviation": "GA",
    "service": false
  },
  {
    "name": "Guam",
    "abbreviation": "GU",
    "service": false
  },
  {
    "name": "Hawaii",
    "abbreviation": "HI",
    "service": false
  },
  {
    "name": "Idaho",
    "abbreviation": "ID",
    "service": false
  },
  {
    "name": "Illinois",
    "abbreviation": "IL",
    "service": false
  },
  {
    "name": "Indiana",
    "abbreviation": "IN",
    "service": false
  },
  {
    "name": "Iowa",
    "abbreviation": "IA",
    "service": false
  },
  {
    "name": "Kansas",
    "abbreviation": "KS",
    "service": false
  },
  {
    "name": "Kentucky",
    "abbreviation": "KY",
    "service": false
  },
  {
    "name": "Louisiana",
    "abbreviation": "LA",
    "service": false
  },
  {
    "name": "Maine",
    "abbreviation": "ME",
    "service": false
  },
  {
    "name": "Marshall Islands",
    "abbreviation": "MH",
    "service": false
  },
  {
    "name": "Maryland",
    "abbreviation": "MD",
    "service": false
  },
  {
    "name": "Massachusetts",
    "abbreviation": "MA",
    "service": false
  },
  {
    "name": "Michigan",
    "abbreviation": "MI",
    "service": false
  },
  {
    "name": "Minnesota",
    "abbreviation": "MN",
    "service": false
  },
  {
    "name": "Mississippi",
    "abbreviation": "MS",
    "service": false
  },
  {
    "name": "Missouri",
    "abbreviation": "MO",
    "service": false
  },
  {
    "name": "Montana",
    "abbreviation": "MT",
    "service": false
  },
  {
    "name": "Nebraska",
    "abbreviation": "NE",
    "service": false
  },
  {
    "name": "Nevada",
    "abbreviation": "NV",
    "service": false
  },
  {
    "name": "New Hampshire",
    "abbreviation": "NH",
    "service": false
  },
  {
    "name": "New Jersey",
    "abbreviation": "NJ",
    "service": false
  },
  {
    "name": "New Mexico",
    "abbreviation": "NM",
    "service": false
  },
  {
    "name": "New York",
    "abbreviation": "NY",
    "service": false
  },
  {
    "name": "North Carolina",
    "abbreviation": "NC",
    "service": false
  },
  {
    "name": "North Dakota",
    "abbreviation": "ND",
    "service": false
  },
  {
    "name": "Northern Mariana Islands",
    "abbreviation": "MP",
    "service": false
  },
  {
    "name": "Ohio",
    "abbreviation": "OH",
    "service": false
  },
  {
    "name": "Oklahoma",
    "abbreviation": "OK",
    "service": false
  },
  {
    "name": "Oregon",
    "abbreviation": "OR",
    "service": false
  },
  {
    "name": "Palau",
    "abbreviation": "PW",
    "service": false
  },
  {
    "name": "Pennsylvania",
    "abbreviation": "PA",
    "service": false
  },
  {
    "name": "Puerto Rico",
    "abbreviation": "PR",
    "service": false
  },
  {
    "name": "Rhode Island",
    "abbreviation": "RI",
    "service": false
  },
  {
    "name": "South Carolina",
    "abbreviation": "SC",
    "service": false
  },
  {
    "name": "South Dakota",
    "abbreviation": "SD",
    "service": false
  },
  {
    "name": "Tennessee",
    "abbreviation": "TN",
    "service": false
  },
  {
    "name": "Texas",
    "abbreviation": "TX",
    "service": false
  },
  {
    "name": "Utah",
    "abbreviation": "UT",
    "service": false
  },
  {
    "name": "Vermont",
    "abbreviation": "VT",
    "service": false
  },
  {
    "name": "Virgin Islands",
    "abbreviation": "VI",
    "service": false
  },
  {
    "name": "Virginia",
    "abbreviation": "VA",
    "service": false
  },
  {
    "name": "Washington",
    "abbreviation": "WA",
    "service": false
  },
  {
    "name": "West Virginia",
    "abbreviation": "WV",
    "service": false
  },
  {
    "name": "Wisconsin",
    "abbreviation": "WI",
    "service": false
  },
  {
    "name": "Wyoming",
    "abbreviation": "WY",
    "service": false
  }
];

export const tosItems = [
  {
    "question": "Do you sincerely believe that your Judge has shown a bias or unfairness in your case?",
    "errorMsg": "Sorry, Disrobing Room is only for litigants whose judge has lacks the ability to be impartial."
  },
  {
    "question": "Do you sincerely believe that your Judge has engaged in judicial misconduct?",
    "errorMsg": "Sorry, Disrobing Room is only for litigants whose judge has engaged in misconduct."
  },
  {
    "question": "Are you registered to vote?",
    "errorMsg": "You must be a registered voter to use Disrobing Room. Please come back after you have registered."
  },
  {
    "question": "Can you print PDF files, either at home or at your local office store?",
    "errorMsg": "You must be able to print PDF documents to use this service."
  },
  {
    "question": "Can you scan paper documents into a PDF file, either at home or at your local office store?",
    "errorMsg": "You must be able to scan paper files to use this service."
  },
  {
    "question": "Do you understand and agree that we are not attorneys and that the use of our service does not constitute legal advice?",
    "errorMsg": "Before you use our service you must understand and agree that we are not offering legal advice."
  },
  {
    "question": "Do you understand and agree that we do not guarantee success in disqualifying your judge?",
    "errorMsg": "Before you use our service you must understand and agree that we do not guarantee success in disqualifying your judge."
  },
];