/*global isMobile*/
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import AvatarEditor from 'react-avatar-editor'

import { ProjSaveButton, PdfDownloadButton, ProjToReportButton } from '../features/ProjButton';
import { changeInput, changeProjects } from '../pages/Dashboard';
import MySnackbar from '../features/MySnackbar';
import { callServerFormdata } from '../features/CallServer';
import Loading from '../features/Loading';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';



/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function ProjectStep10() {
  const { projId, projName, projects, reasonRemoveJudge, caseNumber, courthouseAddress, courthouseCity, courthouseZip, courthouseName, judgeName, userConfirm, state, states, county, stateCounty } = useSelector( state => state.dashboard );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [counties, setCounties] = useState(stateCounty.filter( s => s["STATE"] === state ).map( s => s["COUNTY"] ));
  const currentStep = 0;
  
  useEffect(() => {
    dispatch(changeInput({name: 'activeStep', value: currentStep})); // to handle navigation to this page through direct url
  }, []); // eslint-disable-line react-hooks/exhaustive-deps  
  
  const handleChangeText = e => dispatch(changeInput({ name: e.target.getAttribute('name'), value: e.target.value }));
  const handleChangeCheckBox = e => {
    let _userConfirm = [...userConfirm];
    _userConfirm[currentStep] = e.target.checked;
    dispatch(changeInput({ name: 'userConfirm', value: _userConfirm }));
  }
  
  async function handleAvatarUpload(blob) {
    try{
      const { fileDocId, downloadUrl } = await callServerFormdata('upload-project-file', {
        projId, 
        keyDocId: 'judgePhotoDocId',
        keyDocName: 'judgePhotoDocName',
        keyDocUrl: 'judgePhotoDocUrl',
        contentType: 'image/png',
        fileName: `${judgeName.replace(/[^A-Za-z0-9]{1,}/g, '-')}.png`,
      }, [blob]);
      
      const index = projects.findIndex( p => p.id === projId );
      let _projects = [...projects]; // because projects is immutable; any descenant of projects cannot be modified directly
      _projects[index] = {...projects[index]};
      _projects[index].judgePhotoDocId = fileDocId;
      _projects[index].judgePhotoDocUrl = downloadUrl;
      dispatch(changeProjects(_projects));
      
      dispatch(changeInput({ name: 'judgePhotoDocId', value: fileDocId }));
      dispatch(changeInput({ name: 'judgePhotoDocUrl', value: downloadUrl }));
      setSuccessMsg('File uploaded.');          
    } catch(err) {
      setErrorMsg(err.message);
    } finally {
    }
  }
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="subtitle1">
          Step 1: DRAFT THE NOTICE OF INTENTION
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="projName"
          required
          fullWidth
          id="projName"
          label="Project Name"
          value={projName}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="judgeName"
          required
          fullWidth
          id="judgeName"
          label="Name of Judge"
          value={judgeName}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '100%', sm: '30%'} }} onClick={e => setDialogOpen(true)}>
          Upload photo
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="courthouseName"
          label="Courthouse Name"
          name="courthouseName"
          value={courthouseName}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="courthouseAddress"
          label="Courthouse Address"
          name="courthouseAddress"
          value={courthouseAddress}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="courthouseCity"
          label="Courthouse City"
          name="courthouseCity"
          value={courthouseCity}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="courthouseZip"
          label="Courthouse Zip"
          name="courthouseZip"
          value={courthouseZip}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel shrink id="select-county-label">
          County of
        </InputLabel>
        <Select
          labelId="select-county-label"
          value={county}
          onChange={e => dispatch(changeInput({ name: 'county', value: e.target.value }))}
          native={isMobile}
          inputProps={{ 'aria-label': 'selection of county' }}
          sx={{ mb: 1 }}
        >
          { counties.map( (c, i) => isMobile? <option key={i} value={c}>{c}</option> : <MenuItem key={i} value={c}>{c}</MenuItem> ) }
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel shrink id="select-state-label">
          State of
        </InputLabel>
        <Select
          labelId="select-state-label"
          value={state}
          onChange={e => {
            const _counties = stateCounty.filter( s => s["STATE"] === state ).map( s => s["COUNTY"] );
            setCounties(_counties);
            if (_counties.length > 0 && _counties.indexOf(county) === -1){
              dispatch(changeInput({ name: 'county', value: _counties[0] }));
            }
            dispatch(changeInput({ name: 'state', value: e.target.value }))
          }}
          native={isMobile}
          inputProps={{ 'aria-label': 'selection of state' }}
          sx={{ mb: 1 }}
        >
          { states.map( (c, i) => isMobile? <option key={i} value={c}>{c}</option> : <MenuItem key={i} value={c}>{c}</MenuItem> ) }
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="caseNumber"
          label="Case Number"
          name="caseNumber"
          value={caseNumber}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="reasonRemoveJudge"
          label="Reason for Removing the Judge"
          name="reasonRemoveJudge"
          value={reasonRemoveJudge}
          onChange={handleChangeText}
          multiline={true}
          rows={8}
          helperText={''}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <PdfDownloadButton 
          projId={projId} 
          docType="Notice-of-Intention"
          downloadFileName={`Notice-of-Intention-${judgeName.replace(/[^A-Za-z0-9]{1,}/g, '-')}.pdf`}
          buttonText="Download Notice of Intention"
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <ProjToReportButton 
          projId={projId} 
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Checkbox value="userConfirm" color="primary" checked={userConfirm[currentStep] ?? false}
          onChange={handleChangeCheckBox} />}
          label="I confirm the information is correct."
          name="userConfirm"
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <ProjSaveButton />
      </Grid>
      <Grid item xs={6} sm={6}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep + 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 12}`);
          }}
        >
          Next
        </Button>
      </Grid>
      
      <AvatarUploader dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} judgeName={judgeName} handleAvatarUpload={handleAvatarUpload}/>
      <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
      <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />
    </Grid>
  );
}

function AvatarUploader({dialogOpen, setDialogOpen, judgeName, handleAvatarUpload}) {
  const [imageFile, setImageFile] = useState();
  const [editor, setEditor] = useState();
  const [loading, setLoading] = useState(false);
  const [scaleValue, setScaleValue] = useState(1.0);
  
  useEffect(() => {
      const elem = document.querySelector('input#uploader-input');
      elem.addEventListener("click", () => document.querySelector('form#uploader-form').reset(), false);
      elem.addEventListener("change", handleFiles, false);
      function handleFiles() {
        setImageFile(this.files[0]);
      }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


/*   const tagId = fileName.toLowerCase().replace(/[^A-Za-z0-9]/g, '-');
  const handleDownload = async e => {
    try {
      const resultBlob = await callServerGet(`download-config/${fileName}`);
      const url = window.URL.createObjectURL(resultBlob);
      const link = document.querySelector(`a#${tagId}-download`);
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
    } catch(err) {
      setErrorMsg(err.message);
    }
  } */
  
  return (
    <>
      <form id="uploader-form" style={{display:'none'}}>
        <input type="file" id="uploader-input" />
      </form>
      <Dialog open={dialogOpen} onClose={e => setDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Judge {judgeName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uploading the photo of the judge is optional.
          </DialogContentText>
          <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '60%', sm: '30%'} }} onClick={e => document.querySelector("input#uploader-input").click()}>
            Select photo
          </Button>
          <br/>
          { imageFile &&
            <>
              <AvatarEditor
                image={imageFile}
                ref={e => setEditor(e)}
                width={128}
                height={128}
                border={32}
                borderRadius={64}
                scale={scaleValue}
                color={[255, 255, 255, 0.6]} // RGBA
              />
              <Typography id="continuous-slider" gutterBottom>
                Zoom
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  -
                </Grid>
                <Grid item xs>
                  <Slider
                    value={scaleValue}
                    min={0.5}
                    max={9.5}
                    step={0.1}
                    onChange={(e, newValue) => setScaleValue(newValue)}
                    getAriaValueText={(value, index) => `${Math.round(value*100)}%`}
                    valueLabelFormat={(value) => `${Math.round(value*100)}%`}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                  />
                </Grid>
                <Grid item>
                  +
                </Grid>
              </Grid>
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={e => {
            e.preventDefault();
            e.target.disabled = true;
            setLoading(true);
            editor.getImageScaledToCanvas().toBlob(async function(blob){
              await handleAvatarUpload(blob);
              e.target.disabled = false;
              setLoading(false);
              setDialogOpen(false);
            });
          }} color="primary">
            {loading? <Loading /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}