/*global db, isMobile, delay*/
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import AvatarEditor from 'react-avatar-editor'
import AdapterDayjs from '@material-ui/lab/AdapterDayjs';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';

import { changeInput, changeStateCounty } from '../pages/Dashboard';
import MySnackbar from '../features/MySnackbar';
import { reportWebSite } from '../features/Constants';
import { callServerGet, callServerPost, callServerFormdata } from '../features/CallServer';
import Loading from '../features/Loading';
import { useReportSave, ReportToProjButton } from '../features/ProjButton';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch, batch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
function CustomButton({children, onClick, ...rest}){
  const [loading, setLoading] = useState(false);
  const handleClick = async e => {
    try {
      setLoading(true);
      await onClick(e);
    } finally {
      setLoading(false);
    }
  }
  
  return (
    <Button 
      {...rest}
      onClick={handleClick}
    >
      {loading? <Loading /> : children}
    </Button>
  );
}

export default function ReportEdit() {
  const { reports, reportName, report_reasonRemoveJudge, report_caseNumber, report_courthouseAddress, report_courthouseCity, report_courthouseZip, report_courthouseName, report_judgeName, state, states, 
    report_county, counties, stateCounty, reportPublishedLinkPath,
    report_title, report_department, report_personalityJudge, report_externalLink1, report_nameExternalLink1, report_externalLink2, report_nameExternalLink2, report_startDateJudgeship,
  } = useSelector( state => state.dashboard );
  const { reportId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportSave = useReportSave();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  //const [counties, setCounties] = useState(stateCounty.filter( s => s["STATE"] === state ).map( s => s["COUNTY"] ));
  
  useEffect(() => {
    if (reportId) dispatch(ReportEdit.getInitialData({ reportId }));
  }, [reportId]); // eslint-disable-line react-hooks/exhaustive-deps  
  
  const handleChangeText = e => dispatch(changeInput({ name: e.target.getAttribute('name'), value: e.target.value }));
  
  const handleSaveReport = async e => {
    try{
      await reportSave();
      setSuccessMsg("Saved");
    } catch(err) {
      setErrorMsg(err.message);
    }
  }
  
  const handleDeleteReport = async e => {
    try{
      await callServerPost('report-delete', { reportId });
      setDialogDeleteOpen(false);
      setSuccessMsg("Deleted");
      dispatch(changeInput({ name: 'reports', value: reports.filter( p => p.id !== reportId ) }));
      await delay(2000);
      navigate('/dashboard/report/list');
    } catch(err) {
      setErrorMsg(err.message);
    }
  }
  
  const handlePublishReport = async e => {
    try{
      await reportSave();
      const { reportPublishedLinkPath: _reportPublishedLinkPath } = await callServerPost('report-publish', { reportId });
      dispatch(changeInput({ name: 'reportPublishedLinkPath', value: _reportPublishedLinkPath }));
      setSuccessMsg("Published");
    } catch(err) {
      setErrorMsg(err.message);
    }
  }
  
  const handleUnpublishReport = async e => {
    try{
      await callServerPost('report-unpublish', { reportId });
      dispatch(changeInput({ name: 'reportPublishedLinkPath', value: null }));
      setSuccessMsg("Unpublished");
    } catch(err) {
      setErrorMsg(err.message);
    }
  }
  
  async function handleAvatarUpload(blob) {
    try{
      const { fileDocId, downloadUrl } = await callServerFormdata('upload-report-file', {
        reportId, 
        keyDocId: 'judgePhotoDocId',
        keyDocName: 'judgePhotoDocName',
        keyDocUrl: 'judgePhotoDocUrl',
        contentType: 'image/png',
        fileName: `${report_judgeName.replace(/[^A-Za-z0-9]{1,}/g, '-')}.png`,
      }, [blob]);
      
      const index = reports.findIndex( p => p.id === reportId );
      let _reports = [...reports]; // because reports is immutable; any descenant of reports cannot be modified directly
      _reports[index] = {...reports[index]};
      _reports[index].judgePhotoDocId = fileDocId;
      _reports[index].judgePhotoDocUrl = downloadUrl;
      dispatch(changeInput({ name: 'reports', value: _reports }));
      
      dispatch(changeInput({ name: 'report_judgePhotoDocId', value: fileDocId }));
      dispatch(changeInput({ name: 'report_judgePhotoDocUrl', value: downloadUrl }));
      setSuccessMsg('File uploaded.');          
    } catch(err) {
      setErrorMsg(err.message);
    } finally {
    }
  }
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="subtitle1">
          POST A REPORT ABOUT YOUR JUDGE
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="report_title"
          required
          fullWidth
          id="report_title"
          label="Report Title"
          value={report_title}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="reportName"
          required
          fullWidth
          id="reportName"
          label="Report Name"
          value={reportName}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="report_judgeName"
          required
          fullWidth
          id="report_judgeName"
          label="Name of Judge"
          value={report_judgeName}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '100%', sm: '30%'} }} onClick={e => setDialogOpen(true)}>
          Upload photo
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="report_courthouseName"
          label="Courthouse Name"
          name="report_courthouseName"
          value={report_courthouseName}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="report_department"
          label="Department"
          name="report_department"
          value={report_department}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="report_courthouseAddress"
          label="Courthouse Address"
          name="report_courthouseAddress"
          value={report_courthouseAddress}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="report_courthouseCity"
          label="Courthouse City"
          name="report_courthouseCity"
          value={report_courthouseCity}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="report_courthouseZip"
          label="Courthouse Zip"
          name="report_courthouseZip"
          value={report_courthouseZip}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel shrink id="select-county-label">
          County of
        </InputLabel>
        <Select
          labelId="select-county-label"
          value={report_county}
          onChange={e => dispatch(changeInput({ name: 'report_county', value: e.target.value }))}
          native={isMobile}
          inputProps={{ 'aria-label': 'selection of county' }}
          sx={{ mb: 1 }}
        >
          { counties.map( (c, i) => isMobile? <option key={i} value={c}>{c}</option> : <MenuItem key={i} value={c}>{c}</MenuItem> ) }
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel shrink id="select-state-label">
          State of
        </InputLabel>
        <Select
          labelId="select-state-label"
          value={state}
          onChange={e => {
            const _state = e.target.value;
            const _counties = stateCounty.filter( s => s["STATE"] === _state ).map( s => s["COUNTY"] );
            //setCounties(_counties);
            dispatch(changeInput({ name: 'counties', value: _counties }));
            if (_counties.length > 0 && _counties.indexOf(report_county) === -1){
              dispatch(changeInput({ name: 'report_county', value: _counties[0] }));
            }
            dispatch(changeInput({ name: 'state', value: _state }))
          }}
          native={isMobile}
          inputProps={{ 'aria-label': 'selection of state' }}
          sx={{ mb: 1 }}
        >
          { states.map( (c, i) => isMobile? <option key={i} value={c}>{c}</option> : <MenuItem key={i} value={c}>{c}</MenuItem> ) }
        </Select>
      </Grid>      

      <Grid item xs={12} sm={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date of Judgeship"
            value={report_startDateJudgeship}
            onChange={(newValue) => {
              dispatch(changeInput({ name: 'report_startDateJudgeship', value: newValue.valueOf() }))
            }}
            renderInput={(params) => (
              <TextField 
                {...params} 
                helperText={'mm/dd/yyyy'} 
              />
            )}
            cancelText=""
            okText="close"
          />
        </LocalizationProvider>
      </Grid>
      
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="report_caseNumber"
          label="Case Number"
          name="report_caseNumber"
          value={report_caseNumber}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="report_reasonRemoveJudge"
          label="Reason for Removing the Judge (1000 word limit)"
          name="report_reasonRemoveJudge"
          value={report_reasonRemoveJudge}
          onChange={handleChangeText}
          multiline={true}
          rows={8}
          inputProps={{ maxLength: 10000 }}
          helperText={''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="report_personalityJudge"
          label="Personality of Judge"
          name="report_personalityJudge"
          value={report_personalityJudge}
          onChange={handleChangeText}
          multiline={true}
          rows={8}
          inputProps={{ maxLength: 10000 }}
          helperText={''}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="report_externalLink1"
          label="External Link 1"
          name="report_externalLink1"
          value={report_externalLink1}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="report_nameExternalLink1"
          label="Name of External Link 1"
          name="report_nameExternalLink1"
          value={report_nameExternalLink1}
          onChange={handleChangeText}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="report_externalLink2"
          label="External Link 2"
          name="report_externalLink2"
          value={report_externalLink2}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="report_nameExternalLink2"
          label="Name of External Link 2"
          name="report_nameExternalLink2"
          value={report_nameExternalLink2}
          onChange={handleChangeText}
        />
      </Grid>

      <Grid item xs={6} sm={6}>
        <CustomButton 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={handleSaveReport}
        >
          Save
        </CustomButton>
      </Grid>

      <Grid item xs={6} sm={6}>
        <CustomButton 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => setDialogDeleteOpen(true)}
        >
          Delete
        </CustomButton>
      </Grid>

      {/*<Grid item xs={6} sm={6}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={async e => {
            //const { protocol, hostname } = window.location;
            //const url = `${protocol}//${hostname}/reports/${reportPublishedLinkPath}`;            
            await reportSave();
            const url = `${domainUrlBackend}/reports-preview/${reportId}`;
            window.open(url, '_blank').focus();
          }}
        >
          Preview 
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="24" height="24"
          viewBox="0 0 24 24"
          style={{'fill':'#FFFFFF'}}><path d="M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h7v2H5v14h14v-7h2v7C21,20.1,20.1,21,19,21z"></path><path d="M21 10L19 10 19 5 14 5 14 3 21 3z"></path><path d="M6.7 8.5H22.3V10.5H6.7z" transform="rotate(-45.001 14.5 9.5)"></path></svg>
        </Button>
      </Grid>*/}

      <Grid item xs={6} sm={6}>
        <CustomButton 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={handlePublishReport}
        >
          Publish
        </CustomButton>
      </Grid>

      { reportPublishedLinkPath && 
        <Grid item xs={6} sm={6}>
          <CustomButton 
            variant="contained" 
            sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
            onClick={handleUnpublishReport}
          >
            Unpublish
          </CustomButton>
        </Grid>
      }

      <Grid item xs={6} sm={6}>
        <ReportToProjButton reportId={reportId} />
      </Grid>
      
      <Grid item xs={12} sm={12}>
        { reportPublishedLinkPath? 
          <a href={`${reportWebSite}${reportPublishedLinkPath}`} target="_blank" rel="noopener noreferrer">Published report</a> :
          <Typography component="h3" variant="subtitle1">
            Report not published yet.
          </Typography>
        }
      </Grid>
      
      <AvatarUploader dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} judgeName={report_judgeName} handleAvatarUpload={handleAvatarUpload}/>
      
      <Dialog open={dialogDeleteOpen} onClose={e => setDialogDeleteOpen(false)} aria-labelledby="form-dialog-delete">
        <DialogTitle id="form-dialog-delete">Please confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to delete this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setDialogDeleteOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteReport} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      
      <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
      <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />
    </Grid>
  );
}

function AvatarUploader({dialogOpen, setDialogOpen, judgeName, handleAvatarUpload}) {
  const [imageFile, setImageFile] = useState();
  const [editor, setEditor] = useState();
  const [loading, setLoading] = useState(false);
  const [scaleValue, setScaleValue] = useState(1.0);
  
  useEffect(() => {
      const elem = document.querySelector('input#uploader-input');
      elem.addEventListener("click", () => document.querySelector('form#uploader-form').reset(), false);
      elem.addEventListener("change", handleFiles, false);
      function handleFiles() {
        setImageFile(this.files[0]);
      }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <>
      <form id="uploader-form" style={{display:'none'}}>
        <input type="file" id="uploader-input" />
      </form>
      <Dialog open={dialogOpen} onClose={e => setDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Judge {judgeName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uploading the photo of the judge is optional.
          </DialogContentText>
          <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '60%', sm: '30%'} }} onClick={e => document.querySelector("input#uploader-input").click()}>
            Select photo
          </Button>
          <br/>
          { imageFile &&
            <>
              <AvatarEditor
                image={imageFile}
                ref={e => setEditor(e)}
                width={196}
                height={196}
                border={32}
                borderRadius={64}
                scale={scaleValue}
                color={[255, 255, 255, 0.6]} // RGBA
              />
              <Typography id="continuous-slider" gutterBottom>
                Zoom
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  -
                </Grid>
                <Grid item xs>
                  <Slider
                    value={scaleValue}
                    min={0.5}
                    max={9.5}
                    step={0.1}
                    onChange={(e, newValue) => setScaleValue(newValue)}
                    getAriaValueText={(value, index) => `${Math.round(value*100)}%`}
                    valueLabelFormat={(value) => `${Math.round(value*100)}%`}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                  />
                </Grid>
                <Grid item>
                  +
                </Grid>
              </Grid>
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={e => {
            e.preventDefault();
            e.target.disabled = true;
            setLoading(true);
            editor.getImageScaledToCanvas().toBlob(async function(blob){
              await handleAvatarUpload(blob);
              e.target.disabled = false;
              setLoading(false);
              setDialogOpen(false);
            });
          }} color="primary">
            {loading? <Loading /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}


ReportEdit.getInitialData = ({ reportId })=> async (dispatch, getState) => {
  const [reportSnapshot, { result: _stateCounty }] = await Promise.all([ // fire three promises together to save time
    db.collection('reports').doc(reportId).get(),
    callServerGet('get-state-county', /*no auth required*/false),
  ]);  
  
  const report = reportSnapshot.data();
  
  batch(() => { // batch is necessary for React ver 17 or earlier; React ver 18 will automatically batch dispatch
    dispatch(changeInput({ name: 'reportName', value: report['reportName'] ?? '' }));
    dispatch(changeInput({ name: 'reportPublishedLinkPath', value: report['reportPublishedLinkPath'] }));
    
    dispatch(changeStateCounty(_stateCounty));
    const _states = _stateCounty.map( s => s["STATE"] ).filter( (value, index, arr) => arr.indexOf(value) === index ); // get state list and remove duplicates
    dispatch(changeInput({name: 'states', value: _states}));
    
    const _state = report['state'] ?? _states[0];
    dispatch(changeInput({ name: 'state', value: _state }));
    
    const _counties = _stateCounty.filter( s => s["STATE"] === _state ).map( s => s["COUNTY"] );
    dispatch(changeInput({ name: 'counties', value: _counties }));
    
    let _county = report['county'] ?? _counties[0];
    dispatch(changeInput({ name: 'report_county', value: _county }));
    
    const keys = [
      'reasonRemoveJudge', 
      'caseNumber',
      'courthouseAddress',
      'courthouseCity',
      'courthouseZip',
      'courthouseName', 
      'judgeName',
      'judgePhotoDocId',
      'judgePhotoDocName',
      'judgePhotoDocUrl',
      'title', 
      'department', 
      'personalityJudge', 
      'externalLink1', 
      'nameExternalLink1', 
      'externalLink2', 
      'nameExternalLink2', 
      'startDateJudgeship',
    ];
    for (const k of keys){
      dispatch(changeInput({ name: `report_${k}`, value: report[k] ?? '' }));
    }    
    dispatch(changeInput({ name: 'reportId', value: reportId })); // to handle navigation to this page through direct url
  });
}