import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, Highlight, Snippet, Pagination, connectStateResults } from 'react-instantsearch-dom';

import logoIcon from '../Disrobing_Room_Logo.webp';
import { reportWebSite } from '../features/Constants';
import { callServerGet } from '../features/CallServer';
import './SearchReport.css';

/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function SearchReport() {
  const [searchClient, setSearchClient] = useState();
  const [indexName, setIndexName] = useState();
  const [showPagination, setShowPagination] = useState(false);
  const initSearchDone = useRef(false);
  //const query = useQuery();
  
  useEffect(() => {
    callServerGet('get-algolia-keys').then(({ algoliaAppId, algoliaApiKeySearchOnly, algoliaIndexName }) => {
      const algoliaClient = algoliasearch(algoliaAppId, algoliaApiKeySearchOnly);
      const _searchClient = {
        ...algoliaClient,
        search: requests => {
          if (initSearchDone.current) return algoliaClient.search(requests);
          initSearchDone.current = true;
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
            })),
          });
        },
      };
      setSearchClient(_searchClient);
      setIndexName(algoliaIndexName);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  

  return (
  <div className="search-report-container">
    <div className="search-report-content-wrap">
      <div className="search-head">
        <img src={logoIcon} alt="logo"/>
        <div className="search-head-right">
          <div className="search-head-signin">
            <Link to="/signup">Sign up</Link>
            &nbsp;/&nbsp;
            <Link to="/signin">Sign in</Link>
          </div>
          <p className="search-head-title"><span>T</span>HE <span>D</span>ISROBING <span>R</span>OOM</p>
          <p className="search-head-subtitle">The emperor has no robe.</p>
        </div>
      </div>
      {indexName &&
      <InstantSearch searchClient={searchClient} indexName={indexName} >
        <Configure hitsPerPage={8} attributesToSnippet={['reasonRemoveJudge']}/>
        <SearchBox translations={{ placeholder: 'Search reports of allegedly corrupt judges', }} searchAsYouType={false} />
        <Results setShowPagination={setShowPagination}>
          <Hits hitComponent={Hit} />
        </Results>
        { showPagination && <Pagination /> }
      </InstantSearch>
      }
    </div>
    <p className="search-report-footer" >
      THE DISROBING ROOM is a joint effort of Post Modern Justice Media Project ("PMJMP") (<a href="http://pmjmp.org">http://pmjmp.org</a>) and Family Court Anti-Corruption Coalition ("FCACC") (<a href="http://familycourtanticorruptioncoalition.com">http://familycourtanticorruptioncoalition.com</a>) THE DISROBING ROOM is an app that allows users to create and publish reports about public officials, which reports contain the statements and opinions of those users. THE DISROBING ROOM, PMJMP, and FCACC claim no copyright and take no responsibility for the accuracy of any user-created content. THE DISROBING ROOM also allows users to draft various documents related to removing biased or unfair public officials through the legal process. Use of THE DISROBING ROOM is at your own risk, and does not constitute legal advice. No results are guaranteed.
    </p>
  </div>
  );
}

/* function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
} */

const Results = connectStateResults(
  ({ searchState, searchResults, children, setShowPagination }) => {
    useEffect(() => {
      setShowPagination(searchResults?.nbPages>1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (searchResults?.nbHits === 0 && searchState?.query) ? (
      <div className="search-no-result">No results have been found for <span>{searchState.query}</span></div>
    ) : (
      children
    )
  }
);

function Hit(props) {
  return (
      <a href={`${reportWebSite}${props.hit.reportPublishedLinkPath}`} target="_blank" rel="noopener noreferrer">
        <div className="hit-name">
          <Highlight attribute="reportName" hit={props.hit} />
        </div>
        <div className="hit-description">
          <Snippet attribute="reasonRemoveJudge" hit={props.hit} />
          <span>&nbsp;[...]</span>
        </div>
      </a>
  );
}