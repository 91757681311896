/*global firebase*/

import { domainUrlBackend } from '../features/Constants';

export const callServerGet = async (method, authRequired=true, server=domainUrlBackend) => {
  let headers = { 'Accept': 'text/html, application/json, application/octet-stream' };
  if (authRequired && firebase.auth().currentUser){
    // there seems to be an issue of the getIdToken function in returning expired token
    // https://github.com/firebase/firebase-ios-sdk/issues/6521
    // so we use forceRefresh to mitigate this issue as Firebase team suggested
    // once the Firebase team solves the underlying issue, forceRefresh should be dropped since it is very expensive
    const idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
    headers["Authorization"] = `Bearer ${idToken}`;
  }
  const res = await fetch(`${server}/${method}`, {
    method: "GET",
    headers,
    credentials: "same-origin"
  });
  if (!res.ok) throw new Error("Bad response from server");
  const contentType = res.headers.get("Content-Type");
  let result;
  if (contentType && contentType.indexOf("application/json") !== -1) {
    result = await res.json();
    if (result.hasOwnProperty('error')) throw new Error(result['error']);
  } else if (contentType && contentType.indexOf("application/octet-stream") !== -1) {
    result = await res.blob();
  }
  return result;
}

export const callServerPost = async (method, data = {}, server=domainUrlBackend) => {
  let headers = { "Content-Type": "application/json" };
  if (firebase.auth().currentUser){
    const idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
    headers["Authorization"] = `Bearer ${idToken}`;
  }
  const res = await fetch(`${server}/${method}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers,
    credentials: "same-origin"
  });
  if (!res.ok) throw new Error("Bad response from server");  
  let result = await res.json();
  if (result.hasOwnProperty('error')) throw new Error(result['error']);
  return result;
}

// upload multipart/form-data
export const callServerFormdata = async (method, data = {}, files = [], server=domainUrlBackend) => {
  let headers = { 'Accept': 'text/html, application/json' };
  if (firebase.auth().currentUser){
    const idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
    headers["Authorization"] = `Bearer ${idToken}`;
  }
  
  const formData  = new FormData();
  for(const key in data) formData.append(key, data[key]);
  for(const file of files) formData.append('config', file);
  
  const res = await fetch(`${server}/${method}`, {
    method: "POST",
    body: formData,
    headers,
    credentials: "same-origin"
  });
  if (!res.ok) throw new Error("Bad response from server");  
  let result = await res.json();
  if (result.hasOwnProperty('error')) throw new Error(result['error']);
  return result;
}

export const callServerPut = async (method, data = {}, server=domainUrlBackend) => {
  const res = await fetch(`${server}/${method}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {"Content-Type": "application/json"},
    credentials: "same-origin"
  });
  if (!res.ok) throw new Error("Bad response from server");  
  let result = await res.json();
  if (result.hasOwnProperty('error')) throw new Error(result['error']);
  return result;
}