/*global firebase globalParam*/
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
/* import LockOutlinedIcon from '@material-ui/icons/LockOutlined'; */
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Copyright from './Copyright';
import { setLocalVariable, getLocalVariable, removeLocalVariable } from '../features/localsave';
import MySnackbar from '../features/MySnackbar';


/*****************************************************************************************
* set up redux for this component
* the reason to make errorMsg in the store is to let it be accessed by
* firebase.auth().onAuthStateChanged in the App.js
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

const thisSlice = createSlice({
  name: 'signin',
  initialState: { errorMsg: '' },
  reducers: {
    changeErrorMsg: (state, { payload }) => {
      state.errorMsg = payload;
    },
  }
});
export const { changeErrorMsg } = thisSlice.actions;
export const reducer = thisSlice.reducer;


/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function SignIn() {
  const errorMsg = useSelector( state => state.signin.errorMsg );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState((getLocalVariable("loginEmail")) ?? '');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(getLocalVariable("rememberMe") === "true");
  
  useEffect(() => {
    return () => dispatch(changeErrorMsg(''));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleRememberMeCheckBox = e => {
    setRememberMe(e.target.checked);
    setLocalVariable("rememberMe", e.target.checked? "true" : "false");
  }
  
  const handleSignIn = async e => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password); // firebase.auth().onAuthStateChanged in App.js will handle the state change.
      if (rememberMe) {
        setLocalVariable("loginEmail", email);
      } else {
        removeLocalVariable("loginEmail");      
      }
    } catch (err) {
      dispatch(changeErrorMsg(err.message));
    } finally {
      e.target.disabled = false;
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar src={globalParam.logo100} sx={{ m: 1, bgcolor: 'secondary.main', cursor: 'pointer' }} onClick={e => navigate('/')}>
          {/*<LockOutlinedIcon />*/}
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') document.querySelector('button#sign-in-button').click();
            }}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" checked={rememberMe} onChange={handleRememberMeCheckBox}/>}
            label="Remember me"
          />
          <Button id="sign-in-button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleSignIn}>
            Sign In
          </Button>
          <Grid container sx={{justifyContent: 'space-between'}}>
            <Grid item xs={12} sm={4}>
              <Link to="/resetpassword">
                Forgot password?
              </Link>
            </Grid>
            <Grid item >
              <Link to="/signup">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <MySnackbar severity='error' message={errorMsg} setMessage={msg => dispatch(changeErrorMsg(msg))} />
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
