/*global */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // load on demand
import timezone from 'dayjs/plugin/timezone' // load on demand
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import AdapterDayjs from '@material-ui/lab/AdapterDayjs';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';

import { ProjSaveButton } from '../features/ProjButton';
import { changeInput } from '../pages/Dashboard';
import MySnackbar from '../features/MySnackbar';

/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ProjectStep70() {
  const { localTimeZone, projId, userConfirm, county, isNoticeOfIntentionFiled, dateNoticeOfIntentionFiled, confirmDateNoticeOfIntentionFiled, addressVoterRegistrar } = useSelector( state => state.dashboard );
  const [dateValidSig, setDateValidSig] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStep = 6;

  useEffect(() => {
    dispatch(changeInput({name: 'activeStep', value: currentStep})); // to handle navigation to this page through direct url
    
/*     (async () => {
      try {
        const record = await callServerGet(`get-state-county-record/${state}/${county}`, false);
        const _addressVoterRegistrar = `${record["ADDRESS OF VOTER REGISTRAR"]}, ${record["CITY OF VOTER REGISTRAR"]}, ${record["STATE OF VOTER REGISTRAR"]} ${record["ZIP CODE OF VOTER REGISTRAR"]}`;
        dispatch(changeInput({name: 'addressVoterRegistrar', value: _addressVoterRegistrar}));
      } catch (err) {
        console.log(err);
        setErrorMsg(err.message);
      } finally {
      }
    })(); */
  }, []); // eslint-disable-line react-hooks/exhaustive-deps  
  
  useEffect(() => {    
    if (dateNoticeOfIntentionFiled){
      const t = dayjs(dateNoticeOfIntentionFiled).tz(localTimeZone).add(30, 'day');
      setDateValidSig(t.format('MMMM DD, YYYY'));
    }
  }, [dateNoticeOfIntentionFiled, localTimeZone]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleChangeCheckBox = e => {
    let _userConfirm = [...userConfirm];
    _userConfirm[currentStep] = e.target.checked;
    dispatch(changeInput({ name: 'userConfirm', value: _userConfirm }));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="subtitle1">
          Step 7: FILE NOTICE OF INTENTION AND PROOF OF SERVICE
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          To file the <i>original</i> Notice of Intention and Proof of Service:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Check online to see if your County Voter Registrar is open, or closed for Covid.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          If it’s open, go to the {county} County Office of the Voter Registrar, located at <b>{addressVoterRegistrar}</b> during business hours.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1">
          If it’s closed, send the Notice of Intention and Proof of Service by Certified Mail to:
        </Typography>
        <Typography component="h3" variant="body1" sx={{paddingLeft: {xs: '10%', sm: '20%'}}}>{county} County Office of the Voter Registrar</Typography>
        <Typography component="h3" variant="body1" sx={{paddingLeft: {xs: '10%', sm: '20%'}}}>Attn: Filing Clerk – Legal Notice to be Filed</Typography>
        <Typography component="h3" variant="body1" sx={{paddingLeft: {xs: '10%', sm: '20%'}, marginBottom: '20px'}}>{addressVoterRegistrar}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Have you filed the original completed Proof of Service and the original of the Notice of Intention at County Records?</FormLabel>
          <RadioGroup aria-label="isNoticeOfIntentionFiled" name="isNoticeOfIntentionFiled1" value={isNoticeOfIntentionFiled? '1' : '0'} onChange={e =>
            dispatch(changeInput({ name: 'isNoticeOfIntentionFiled', value: e.target.value === '1' }))
          }>
            <FormControlLabel value="1" control={<Radio />} label="Yes" />
            <FormControlLabel value="0" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      { isNoticeOfIntentionFiled &&
        <>
          <Grid item xs={12} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Filing"
                value={dateNoticeOfIntentionFiled}
                onChange={(newValue) => {
                  dispatch(changeInput({ name: 'dateNoticeOfIntentionFiled', value: newValue.valueOf() }))
                }}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    helperText={'mm/dd/yyyy'} 
                  />
                )}
                cancelText=""
                okText="close"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Is {dayjs(dateNoticeOfIntentionFiled).tz(localTimeZone).format('MMMM DD, YYYY')} the correct date for when you filed the Notice of Intention?</FormLabel>
              <RadioGroup aria-label="confirmDateNoticeOfIntentionFiled" name="confirmDateNoticeOfIntentionFiled1" value={confirmDateNoticeOfIntentionFiled? '1' : '0'} onChange={e =>
                dispatch(changeInput({ name: 'confirmDateNoticeOfIntentionFiled', value: e.target.value === '1' }))
              }>
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          { confirmDateNoticeOfIntentionFiled &&
            <Grid item xs={12} sm={12}>
              <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
                Great job! Now, the county elections official has until <b>{dateValidSig}</b> to examine the Notice of Intention and determine the total number of valid signatures.
              </Typography>
            </Grid>
          }          
        </>
      }
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Checkbox value="userConfirm" color="primary" checked={userConfirm[currentStep] ?? false}
          onChange={handleChangeCheckBox} />}
          label="I confirm I have completed the filing."
          name="userConfirm"
        />
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep - 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 10}`);
          }}
        >
          Prev
        </Button>
      </Grid>
      <Grid item xs={4} sm={4}>
        <ProjSaveButton />
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep + 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 12}`);
          }}
        >
          Next
        </Button>
      </Grid>
      <Grid item xs={4} sm={4}>      
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
      </Grid>
    </Grid>
  );
}
