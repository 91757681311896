/*global firebase*/
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AdapterDayjs from '@material-ui/lab/AdapterDayjs';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';

import { callServerGet, callServerPost, callServerFormdata } from '../features/CallServer';
import MySnackbar from '../features/MySnackbar';
import Loading from '../features/Loading';


/*****************************************************************************************
* component definition
*****************************************************************************************/
function FileDownloadUpload({ description, fileName }) {
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  
  useEffect(() => {
    document.querySelector(`input#${tagId}-input`).addEventListener("click", () => document.querySelector(`form#${tagId}-form`).reset(), false);
    document.querySelector(`input#${tagId}-input`).addEventListener("change", handleFiles, false);
    async function handleFiles() {
      try{
        await callServerFormdata('upload-config', {fileName}, this.files);
        setSuccessMsg('File uploaded.');
      } catch(err) {
        setErrorMsg(err.message);
      } finally {
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tagId = fileName.toLowerCase().replace(/[^A-Za-z0-9]/g, '-');
  const handleDownload = async e => {
    try {
      const resultBlob = await callServerGet(`download-config/${fileName}`);
      const url = window.URL.createObjectURL(resultBlob);
      const link = document.querySelector(`a#${tagId}-download`);
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
    } catch(err) {
      setErrorMsg(err.message);
    }
  }
  
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Typography component="h2" variant="h6">
          {description}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <a id={`${tagId}-download`} href='/#' style={{display:'none'}}>download</a>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={handleDownload}>
          Download
        </Button>
      </Grid>
      <Grid item xs={6} sm={6}>
        <form id={`${tagId}-form`} style={{display:'none'}}>
          <input type="file" id={`${tagId}-input`} />
        </form>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={e => document.querySelector(`input#${tagId}-input`).click()}>
          Upload
        </Button>
      </Grid>
      <Grid item>
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />      
      </Grid>      
    </>
  )
}



function DatabaseDownloadButton({ name, startTimeUnixMs, endTimeUnixMs }) {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleDownload = async e => {
    e.preventDefault();
    e.target.disabled = true;
    setLoading(true);
    try {
      const collectionId = e.target.getAttribute('name');
      const resultBlob = await callServerGet(`download-database/${collectionId}/${startTimeUnixMs}/${endTimeUnixMs}`);
      const url = window.URL.createObjectURL(resultBlob);
      const link = document.querySelector('a#database-download');
      link.href = url;
      link.setAttribute('download', `${collectionId}.csv`);
      link.click();
    } catch(err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      setLoading(false);
    }
  }
  
  return (
    <Grid item xs={12} sm={4}>
      <Button 
        variant="contained" 
        sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '80%'} }} 
        name={name}
        onClick={handleDownload}
      >
        {loading? <Loading /> : name}
      </Button>
      <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
    </Grid>
  )
}

function DatabaseDownload() {
  const [startTimeUnixMs, setStartTimeUnixMs] = useState();
  const [endTimeUnixMs, setEndTimeUnixMs] = useState();
  
  useEffect(() => {
    (async () => {
      const { start, end } = await callServerGet('get-database-timerange');
      setStartTimeUnixMs(start);
      setEndTimeUnixMs(end);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Typography component="h2" variant="h6">
          Download database
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startTimeUnixMs}
            onChange={(newValue) => {
              setStartTimeUnixMs(newValue.valueOf())
            }}
            renderInput={(params) => (
              <TextField 
                {...params} 
                helperText={'mm/dd/yyyy'} 
              />
            )}
            cancelText=""
            okText="close"
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End Date"
            value={endTimeUnixMs}
            onChange={(newValue) => {
              setEndTimeUnixMs(newValue.valueOf())
            }}
            renderInput={(params) => (
              <TextField 
                {...params} 
                helperText={'mm/dd/yyyy'} 
              />
            )}
            cancelText=""
            okText="close"
          />
        </LocalizationProvider>
      </Grid>
      <DatabaseDownloadButton name="users" startTimeUnixMs={startTimeUnixMs} endTimeUnixMs={endTimeUnixMs}/>
      <DatabaseDownloadButton name="projects" startTimeUnixMs={startTimeUnixMs} endTimeUnixMs={endTimeUnixMs}/>
      <DatabaseDownloadButton name="order-sessions" startTimeUnixMs={startTimeUnixMs} endTimeUnixMs={endTimeUnixMs}/>
      <Grid item xs={12}>
        <a id={'database-download'} href='/#' style={{display:'none'}}>download</a>
      </Grid>
    </>
  )
}

export default function Admin() {
  const [userEmail, setUserEmail] = useState('');
  const [projPrice, setProjPrice] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  
  useEffect(() => {
    (async () => {
      const { projPrice: p } = await callServerGet('get-project-price');
      setProjPrice(p);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleSavePrice = async e => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      await callServerPost('set-project-price', {projPrice});
      setSuccessMsg('Price updated');
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
    }    
  }
  
  const handleSignIn = async e => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      const { customToken } = await callServerPost('user-impersonation', {userEmail});
      await firebase.auth().signOut();
      await firebase.auth().signInWithCustomToken(customToken);
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
    }    
  }
  
  const handleGrantAdmin = async e => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      await callServerPost('grant-admin', {userEmail});
      setSuccessMsg('Admin right granted');
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
    }    
  }
  
  const handleRevokeAdmin = async e => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      await callServerPost('revoke-admin', {userEmail});
      setSuccessMsg('Admin right revoked');
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
    }    
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="projPrice"
          name="projPrice"
          id="projPrice"
          label="Project Price ($)"
          value={projPrice}
          onChange={e => setProjPrice(Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '50%', sm: '40%'} }} onClick={handleSavePrice}>
          Save
        </Button>
      </Grid>
      <div style={{borderBottom: '1px solid gray', width: '100%', marginTop: '20px'}}></div>
      <FileDownloadUpload description="Configuration file" fileName="Disrobing-Room-Configuration.csv"/>
      <div style={{borderBottom: '1px solid gray', width: '100%'}}></div>
      <FileDownloadUpload description="Template for Post Judge Report" fileName="Template-Post-Judge-Report.html"/>
      <div style={{borderBottom: '1px solid gray', width: '100%'}}></div>
      <FileDownloadUpload description="Template for Notice of Intention" fileName="Template-Notice-of-Intention.html"/>
      <div style={{borderBottom: '1px solid gray', width: '100%'}}></div>
      <FileDownloadUpload description="Template for Affidavit to Disqualify" fileName="Template-Affidavit-to-Disqualify.html"/>
      <div style={{borderBottom: '1px solid gray', width: '100%'}}></div>
      <FileDownloadUpload description="Template for Proof of Service Personal" fileName="Template-Proof-of-Service-Personal.html"/>
      <div style={{borderBottom: '1px solid gray', width: '100%'}}></div>
      <FileDownloadUpload description="Template for Proof of Service Mail" fileName="Template-Proof-of-Service-Mail.html"/>
      <div style={{borderBottom: '1px solid gray', width: '100%'}}></div>
      <FileDownloadUpload description="Template for Request for Affidavit of Publication" fileName="Template-Request-for-Affidavit-of-Publication.html"/>
      <div style={{borderBottom: '1px solid gray', width: '100%'}}></div>
      <DatabaseDownload />
      <div style={{borderBottom: '1px solid gray', width: '100%'}}></div>
      <Grid item xs={12} sm={12}>
        <Typography component="h2" variant="h6">
          Manage a user
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="userEmail"
          name="userEmail"
          id="userEmail"
          label="User email"
          value={userEmail}
          onChange={e => setUserEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '50%', sm: '40%'} }} onClick={handleSignIn}>
          Impersonate
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '50%', sm: '40%'} }} onClick={handleGrantAdmin}>
          Grant admin
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '50%', sm: '40%'} }} onClick={handleRevokeAdmin}>
          Revoke admin
        </Button>
      </Grid>
      <Grid item>
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />      
      </Grid>
    </Grid>
  );
}
