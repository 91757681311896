/*global */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // load on demand
import timezone from 'dayjs/plugin/timezone' // load on demand
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ProjSaveButton, PdfDownloadButton, PdfDownloadUpload } from '../features/ProjButton';
import MySnackbar from '../features/MySnackbar';
import { changeInput } from '../pages/Dashboard';

/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ProjectStep90() {
  const { 
    projId, userConfirm, judgeName, reasonRecuseJudge,
    nameOfTopParty, designationOfTopParty, nameOfBottomParty, designationOfBottomParty, isAttorney, attorneyName, attorneyAddress, attorneyCity, attorneyState, attorneyZip,
  } = useSelector( state => state.dashboard );
  const [pdfFileName, setPdfFileName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStep = 8;

  useEffect(() => {
    dispatch(changeInput({name: 'activeStep', value: currentStep})); // to handle navigation to this page through direct url
  }, []); // eslint-disable-line react-hooks/exhaustive-deps  
  
  useEffect(() => {    
    if (judgeName) setPdfFileName(`Affidavit-to-Disqualify-Judge-${judgeName.replace(/[^A-Za-z0-9]{1,}/g, '-')}.pdf`);
  }, [judgeName]); // eslint-disable-line react-hooks/exhaustive-deps  

  const handleChangeText = e => dispatch(changeInput({ name: e.target.getAttribute('name'), value: e.target.value }));
  const handleChangeCheckBox = e => {
    let _userConfirm = [...userConfirm];
    _userConfirm[currentStep] = e.target.checked;
    dispatch(changeInput({ name: 'userConfirm', value: _userConfirm }));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="subtitle1">
          Step 9: DRAFT AFFIDAVIT TO DISQUALIFY JUDGE
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="nameOfTopParty"
          required
          fullWidth
          id="nameOfTopParty"
          label="Name of Plaintiff or Petitioner"
          value={nameOfTopParty}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="designationOfTopParty"
          required
          fullWidth
          id="designationOfTopParty"
          label="E.g. Plaintiff, Petitioner, etc."
          value={designationOfTopParty}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="nameOfBottomParty"
          required
          fullWidth
          id="nameOfBottomParty"
          label="Name of Defendant or Respondent"
          value={nameOfBottomParty}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="designationOfBottomParty"
          required
          fullWidth
          id="designationOfBottomParty"
          label="E.g. Defendant, Respondent, etc."
          value={designationOfBottomParty}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sx={{marginTop: '14px',}}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Do you have an attorney to file your Affidavit to Disqualify Hon. {judgeName}?</FormLabel>
          <RadioGroup aria-label="isAttorney" name="isAttorney1" value={isAttorney? '1' : '0'} onChange={e =>
            dispatch(changeInput({ name: 'isAttorney', value: e.target.value === '1' }))
          }>
            <FormControlLabel value="1" control={<Radio />} label="Yes" />
            <FormControlLabel value="0" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      { isAttorney &&
        <>
          <Grid item xs={12} sm={12}>
            <TextField
              name="attorneyName"
              required
              fullWidth
              id="attorneyName"
              label="Attorney Name with State Bar Number"
              value={attorneyName}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="attorneyAddress"
              required
              fullWidth
              id="attorneyAddress"
              label="Name of Attorney's Law Office"
              value={attorneyAddress}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="attorneyAddress"
              required
              fullWidth
              id="attorneyAddress"
              label="Address of Attorney's Law Office"
              value={attorneyAddress}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="attorneyCity"
              required
              fullWidth
              id="attorneyCity"
              label="City of Attorney's Law Office"
              value={attorneyCity}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="attorneyState"
              required
              fullWidth
              id="attorneyState"
              label="State of Attorney's Law Office"
              value={attorneyState}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="attorneyZip"
              required
              fullWidth
              id="attorneyZip"
              label="Zip of Attorney's Law Office"
              value={attorneyZip}
              onChange={handleChangeText}
            />
          </Grid>
        </>
      }
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginTop: '14px', marginBottom: '12px',}}>
          Use complete sentences. Use as many words as you need – up to 5000 word max – but no more than necessary.  Don’t repeat yourself, and stick to the reasons why a reasonable person, if they knew the facts, would believe your judge was not being fair.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          id="reasonRecuseJudge"
          label="Reason for Recusing the Judge"
          name="reasonRecuseJudge"
          value={reasonRecuseJudge}
          onChange={handleChangeText}
          multiline={true}
          rows={8}
          helperText={''}
        />
      </Grid>
      <Grid item xs={12}>
        <PdfDownloadButton 
          projId={projId} 
          docType="Affidavit-to-Disqualify"
          downloadFileName={pdfFileName}
          buttonText="Download Affidavit to Disqualify"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Print and sign <b>{pdfFileName}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          Scan the signed Affidavit to Disqualify Hon. {judgeName} to a PDF.
        </Typography>
      </Grid>
      <PdfDownloadUpload 
        description={`Upload the signed Affidavit to Disqualify Hon. ${judgeName}:`}
        fileName={`Affidavit-to-Disqualify-signed-Hon-${judgeName.replace(/[^A-Za-z0-9]{1,}/g, '-')}.pdf`}
        keyDocId="affidavitToDisqualifyDocId"
        keyDocName="affidavitToDisqualifyDocName"
        keyDocUrl="affidavitToDisqualifyDocUrl"
      />
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Checkbox value="userConfirm" color="primary" checked={userConfirm[currentStep] ?? false}
          onChange={handleChangeCheckBox} />}
          label="I confirm I have signed and uploaded the Affidavit to Disqualify."
          name="userConfirm"
        />
      </Grid>
      <Grid item xs={4} sm={4} sx={{marginTop: '14px',}}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep - 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 10}`);
          }}
        >
          Prev
        </Button>
      </Grid>
      <Grid item xs={4} sm={4} sx={{marginTop: '14px',}}>
        <ProjSaveButton />
      </Grid>
      <Grid item xs={4} sm={4} sx={{marginTop: '14px',}}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep + 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 12}`);
          }}
        >
          Next
        </Button>
      </Grid>
      <Grid item>
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
      </Grid>
      
    </Grid>
  );
}
