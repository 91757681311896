import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { changeInput } from './Dashboard';
import MySnackbar from '../features/MySnackbar';
import { callServerPost } from '../features/CallServer';

/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';

/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function ReportList() {
  const reports = useSelector( state => state.dashboard.reports );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  return (
      <Grid container spacing={3}>
        
        { 
          reports.map((r, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Paper
                sx={{
                  paddingLeft: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  flexWrap: 'wrap',
                  height: 110,
                  cursor: 'pointer',
                }}
                onClick={e => {
                  dispatch(changeInput({ name: 'reportId', value: r.id }));
                  navigate(`/dashboard/report/${r.id}`);
                }}
              >
                <Typography sx={{flex: '1 0 100%'}} variant="h5">Report:</Typography>
                <Typography variant="h5">{r.reportName}</Typography>
              </Paper>
            </Grid>
          ))
        }

        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              paddingLeft: '30px',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              flexWrap: 'wrap',
              height: 110,
              cursor: 'pointer',
            }}
            onClick={async e => {
              try{
                const { reportId, reportName } = await callServerPost('report-create');
                dispatch(changeInput({ name: 'reports', value: [...reports, { id: reportId, reportName }] }));
                navigate(`/dashboard/report/${reportId}`);         
              } catch(err) {
                setErrorMsg(err.message);
              }
            }}
          >
            <Typography variant="h5">Add new report</Typography>
          </Paper>
        </Grid>
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        
      </Grid>
  );
}
