/*global isMobile*/
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import AdapterDayjs from '@material-ui/lab/AdapterDayjs';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import TimePicker from '@material-ui/lab/TimePicker';

import { ProjSaveButton } from '../features/ProjButton';
import { changeInput } from '../pages/Dashboard';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function ProjectStep40() {
  const { 
    projId, userConfirm, 
    courthouseAddress, courthouseName, judgeName,
    serverFirstName, serverMiddleInitial, serverLastName, serverAddress, serverCity, serverState, serverZip, methodOfService, isPresidingJudge, presJudgeName, presJudgeState, presJudgeCounty, presJudgeCourthouseName, presJudgeCourthouseAddress, isJudgeServed, dateOfService, timeOfService, dateOfProof, cityOfProof, stateOfProof, } = useSelector( state => state.dashboard );
  const [serverFullName, setServerFullName] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStep = 3;
  
  useEffect(() => {
    dispatch(changeInput({name: 'activeStep', value: currentStep})); // to handle navigation to this page through direct url
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    const _serverFullName = (serverMiddleInitial.length === 0)? `${serverFirstName} ${serverLastName}` : `${serverFirstName} ${serverMiddleInitial} ${serverLastName}`;
    setServerFullName(_serverFullName);
  }, [serverFirstName, serverMiddleInitial, serverLastName]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleChangeText = e => dispatch(changeInput({ name: e.target.getAttribute('name'), value: e.target.value }));
  const handleChangeCheckBox = e => {
    let _userConfirm = [...userConfirm];
    _userConfirm[currentStep] = e.target.checked;
    dispatch(changeInput({ name: 'userConfirm', value: _userConfirm }));
  }  

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="subtitle1">
          Step 4: SERVE THE JUDGE
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginTop: '8px',}}>
          Who will be your process server? It can be anyone over 18 who is not you.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="serverFirstName"
          required
          fullWidth
          id="serverFirstName"
          label="First Name"
          value={serverFirstName}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="serverMiddleInitial"
          fullWidth
          id="serverMiddleInitial"
          label="Middle Initial"
          value={serverMiddleInitial}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="serverLastName"
          label="Last Name"
          name="serverLastName"
          value={serverLastName}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="serverAddress"
          label="Server Address"
          name="serverAddress"
          value={serverAddress}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="serverCity"
          label="Server City"
          name="serverCity"
          value={serverCity}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="serverState"
          label="Server State"
          name="serverState"
          value={serverState}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="serverZip"
          label="Server Zip"
          name="serverZip"
          value={serverZip}
          onChange={handleChangeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">How will the judge be served the papers?</FormLabel>
          <Select
            value={methodOfService}
            onChange={e => dispatch(changeInput({ name: 'methodOfService', value: e.target.value }))}
            native={isMobile}
            inputProps={{ 'aria-label': 'selection of service method' }}
            sx={{ mb: 1 }}
          >
            { ['Personal Service', 'Certified Mail'].map( (c, i) => isMobile? <option key={i} value={c}>{c}</option> : <MenuItem key={i} value={c}>{c}</MenuItem> ) }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Is there a Presiding Judge in your courthouse?</FormLabel>
          <RadioGroup aria-label="isPresidingJudge" name="isPresidingJudge1" value={isPresidingJudge? '1' : '0'} onChange={e =>
            dispatch(changeInput({ name: 'isPresidingJudge', value: e.target.value === '1' }))
          }>
            <FormControlLabel value="1" control={<Radio />} label="Yes" />
            <FormControlLabel value="0" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      { isPresidingJudge &&
        <>
          <Grid item xs={12} sm={12}>
            <Typography component="h3" variant="body1" sx={{marginTop: '8px',}}>
              The presiding judge's information:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="presJudgeName"
              label="Name of Presiding Judge"
              name="presJudgeName"
              value={presJudgeName}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="presJudgeState"
              label="State of Presiding Judge"
              name="presJudgeState"
              value={presJudgeState}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="presJudgeCounty"
              label="County of Presiding Judge"
              name="presJudgeCounty"
              value={presJudgeCounty}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="presJudgeCourthouseName"
              label="Name of Presiding judge's Courthouse"
              name="presJudgeCourthouseName"
              value={presJudgeCourthouseName}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="presJudgeCourthouseAddress"
              label="Address of Presiding judge's Courthouse"
              name="presJudgeCourthouseAddress"
              value={presJudgeCourthouseAddress}
              onChange={handleChangeText}
              sx={{marginBottom: '15px'}}
            />
          </Grid>
        </>
      }
      { (methodOfService === 'Personal Service') && 
        <Grid item xs={12} sm={12}>
          <Typography component="h3" variant="body1">
            Have {serverFullName} personally serve a <b>copy</b> of the Notice of Intention (with signatures) on the judge’s courtroom, and the presiding judge’s courtroom.
          </Typography>
        </Grid>
      }
      { (methodOfService === 'Certified Mail') && 
        <Grid item xs={12} sm={12}>
          <Typography component="h3" variant="body1">
            Have {serverFullName} send by <b>Certified Mail</b> (at the U.S. Post Office) a <b>copy</b> of the Notice of Intention (with signatures) on the judge’s courtroom, and the presiding judge’s courtroom. The mailing address is:
          </Typography>
          <Typography component="h3" variant="body1" sx={{paddingLeft: {xs: '15%', sm: '30%'}}}>Hon. {judgeName}</Typography>
          <Typography component="h3" variant="body1" sx={{paddingLeft: {xs: '15%', sm: '30%'}}}>c/o {courthouseName}</Typography>
          <Typography component="h3" variant="body1" sx={{paddingLeft: {xs: '15%', sm: '30%'}, marginBottom: '20px'}}>{courthouseAddress}</Typography>
          { isPresidingJudge &&
            <>
              <Typography component="h3" variant="body1" sx={{paddingLeft: {xs: '15%', sm: '30%'}}}>Hon. {presJudgeName}</Typography>
              <Typography component="h3" variant="body1" sx={{paddingLeft: {xs: '15%', sm: '30%'}}}>c/o {presJudgeCourthouseName}</Typography>
              <Typography component="h3" variant="body1" sx={{paddingLeft: {xs: '15%', sm: '30%'}}}>{presJudgeCourthouseAddress}</Typography>
            </>
          }
        </Grid>
      }
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Has the Judge been served?</FormLabel>
          <RadioGroup aria-label="isJudgeServed" name="isJudgeServed1" value={isJudgeServed? '1' : '0'} onChange={e =>
            dispatch(changeInput({ name: 'isJudgeServed', value: e.target.value === '1' }))
          }>
            <FormControlLabel value="1" control={<Radio />} label="Yes" />
            <FormControlLabel value="0" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      { isJudgeServed &&
        <>
          <Grid item xs={12} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of service"
                value={dateOfService}
                onChange={(newValue) => {
                  dispatch(changeInput({ name: 'dateOfService', value: newValue.valueOf() }))
                }}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    helperText={'mm/dd/yyyy'} 
                  />
                )}
                cancelText=""
                okText="close"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Time of Service"
                value={timeOfService}
                onChange={(newValue) => {
                  dispatch(changeInput({ name: 'timeOfService', value: newValue.valueOf() }))
                }}
                renderInput={(params) => <TextField {...params} />}
                cancelText=""
                okText="close"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography component="h3" variant="body1" sx={{marginTop: '8px',}}>
              When and where did you execute the proof of service?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Proof"
                value={dateOfProof}
                onChange={(newValue) => {
                  dispatch(changeInput({ name: 'dateOfProof', value: newValue.valueOf() }))
                }}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    helperText={'mm/dd/yyyy'} 
                  />
                )}
                cancelText=""
                okText="close"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="cityOfProof"
              label="City of Proof"
              name="cityOfProof"
              value={cityOfProof}
              onChange={handleChangeText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="stateOfProof"
              label="State Of Proof"
              name="stateOfProof"
              value={stateOfProof}
              onChange={handleChangeText}
            />
          </Grid>
        </>
      }
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Checkbox value="userConfirm" color="primary" checked={userConfirm[currentStep] ?? false}
          onChange={handleChangeCheckBox} />}
          label="I confirm the information is correct."
          name="userConfirm"
        />
      </Grid>
      <Grid item xs={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep - 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 10}`);
          }}
        >
          Prev
        </Button>
      </Grid>
      <Grid item xs={4}>
        <ProjSaveButton />
      </Grid>
      <Grid item xs={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep + 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 12}`);
          }}
        >
          Next
        </Button>
      </Grid>      
    </Grid>
  );
}
