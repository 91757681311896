import { configureStore } from '@reduxjs/toolkit';
import { reducer as signinReducer } from '../pages/SignIn';
import { reducer as resetpasswordReducer } from '../pages/ResetPassword';
import { reducer as signupReducer } from '../pages/SignUp';
import { reducer as appReducer } from '../App';
import { reducer as dashboardReducer } from '../pages/Dashboard';

export const store = configureStore({
  reducer: {
    signin: signinReducer,
    resetpassword: resetpasswordReducer,
    signup: signupReducer,
    app: appReducer,
    dashboard: dashboardReducer,
  },
});
