/*global firebase, db, delay, globalParam, isMobile*/
import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // load on demand
import timezone from 'dayjs/plugin/timezone' // load on demand
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MuiDrawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Copyright from './Copyright';
import MySnackbar from '../features/MySnackbar';
import { callServerGet, callServerPost } from '../features/CallServer';

/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch, batch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';//

const thisSlice = createSlice({
  name: 'dashboard',
  initialState: {
    stateCounty: [],
    states: [],
    state: '',
    county: '',
    
    // step 1
    projId: '',
    projName: '',
    reasonRemoveJudge: '', 
    caseNumber: '',
    courthouseAddress: '',
    courthouseCity: '',
    courthouseZip: '',
    courthouseName: '', 
    judgeName: '',
    userConfirm: [],
    activeStep: 0,
    judgePhotoDocId: '',
    judgePhotoDocName: '',
    judgePhotoDocUrl: '',
    
    // step 3
    noticeOfIntentionDocId: '',
    noticeOfIntentionDocName: '',
    noticeOfIntentionDocUrl: '',
    
    // step 4
    serverFirstName: '',
    serverMiddleInitial: '',
    serverLastName: '',
    serverAddress: '',
    serverCity: '',
    serverState: '',
    serverZip: '',
    methodOfService: 'Personal Service',
    isPresidingJudge: false,
    presJudgeName: '',
    presJudgeState: '',
    presJudgeCounty: '',
    presJudgeCourthouseName: '',
    presJudgeCourthouseAddress: '',
    isJudgeServed: false,
    dateOfService: null, // filingDeadline = dateOfService + 7; Unix Timestamp (milliseconds)
    timeOfService: null,
    dateOfProof: null,
    cityOfProof: '',
    stateOfProof: '',
    
    // step 6
    proofOfServiceDocId: '',
    proofOfServiceDocName: '',
    proofOfServiceDocUrl: '',
    
    // step 7
    isNoticeOfIntentionFiled: false,
    dateNoticeOfIntentionFiled: null, // dateOfDetermineValidSig = dateNoticeOfIntentionFiled + 30
    confirmDateNoticeOfIntentionFiled: false,
    addressVoterRegistrar: '',
    
    // step 9
    reasonRecuseJudge: '',
    affidavitToDisqualifyDocId: '',
    affidavitToDisqualifyDocName: '',
    affidavitToDisqualifyDocUrl: '',
    nameOfTopParty: '',
    designationOfTopParty: '',
    nameOfBottomParty: '',
    designationOfBottomParty: '',
    isAttorney: false,
    attorneyName: '',
    attorneyAddress: '',
    attorneyCity: '',
    attorneyState: '',
    attorneyZip: '',
    
    // add report
    reports: [],
    reportId: '',
    reportName: '',
    report_reasonRemoveJudge: '', 
    report_caseNumber: '',
    report_courthouseAddress: '',
    report_courthouseCity: '',
    report_courthouseZip: '',
    report_courthouseName: '', 
    report_judgeName: '',
    report_judgePhotoDocId: '',
    report_judgePhotoDocName: '',
    report_judgePhotoDocUrl: '',
    reportPublishedLinkPath: null,
    report_county: '',
    counties: [],
    report_title: '',
    report_department: '',
    report_personalityJudge: '',
    report_externalLink1: '',
    report_nameExternalLink1: '',
    report_externalLink2: '',
    report_nameExternalLink2: '',
    report_startDateJudgeship: null, //Unix Timestamp (milliseconds)
    
    // settings
    firstName: '', middleInitial: '', lastName: '', address: '', phone: '',
    consentToDailySms: false, consentToDailyEmail: false, isPhoneVerified: false,
    timeForText: 600, timeForEmail: 600, // in minutes since start of the day
    localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    email: '', isAdmin: false,
    projects: [],
    
    // dashboard
    dataLoadedDashboard: false,
  },
  reducers: {
    changeInput: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    changeProjects: (state, { payload }) => {
      state['projects'] = payload;
    },
    changeStateCounty: (state, { payload }) => {
      state['stateCounty'] = payload;
    },
  }
});
export const { changeInput, changeProjects, changeStateCounty } = thisSlice.actions;
export const reducer = thisSlice.reducer;

/*****************************************************************************************
* component definition
*****************************************************************************************/
function ProjectItem({project: p, currentProjId}){
  /* const [imageSrc, setImageSrc] = useState(p.judgePhotoDocUrl ?? globalParam.logo100); */
  const dispatch = useDispatch();
  const navigate = useNavigate();

/*   useEffect(() => {
    (async () => {
      if (!p.judgePhotoDocUrl) return setImageSrc(globalParam.logo100);
      try {
        const url = p.judgePhotoDocUrl;
        setImageSrc(url);
      } catch(error) {
      }
    })();
  }, [p.judgePhotoDocUrl]); // eslint-disable-line react-hooks/exhaustive-deps */
  
  return (
    <ListItem button key={p.id} onClick={e => {
      dispatch(changeInput({name: 'activeStep', value: 0}));
      dispatch(changeInput({ name: 'projId', value: p.id }));
      navigate(`project/${p.id}/11`);
    }}>
      <ListItemIcon>
        {/* p.judgePhotoDocId? <Avatar alt="Judge" src={imageSrc} sx={{width: '25px', height: '25px'}}/> : <MenuBookIcon sx={(currentProjId === p.id)? {color: 'primary.main'} : {}}/>*/}
        <Avatar alt="Judge" src={p.judgePhotoDocUrl ?? globalParam.logo100} sx={{width: '25px', height: '25px'}}/>
      </ListItemIcon>
      <ListItemText primary={p.projName.slice(0, 18)} />
    </ListItem>
  )
}

function ReportItem({report: p}){
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <ListItem button key={p.id} onClick={e => {
      dispatch(changeInput({ name: 'reportId', value: p.id }));
      navigate(`report/${p.id}`);
    }}>
      <ListItemIcon>
        <Avatar alt="Judge" src={p.judgePhotoDocUrl ?? globalParam.logo100} sx={{width: '25px', height: '25px'}}/>
      </ListItemIcon>
      <ListItemText primary={p.reportName.slice(0, 18)} />
    </ListItem>
  )
}


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
      zIndex: theme.zIndex.drawer - 1,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
      },
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
/*         width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9), */
        width: theme.spacing(9),
        [theme.breakpoints.down('sm')]: {
          width: theme.spacing(0),
          position: 'absolute',
        },
      }),
    },
  }),
);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

dayjs.extend(utc);
dayjs.extend(timezone);
function minuteDiffBetweenTimeZone(timezone1, timezone2='UTC'){
  const t = dayjs();
  const msDiff = t.tz(timezone1, true).valueOf() - t.tz(timezone2, true).valueOf();
  return Math.round(msDiff/60000);
}

function Dashboard() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const [openPopover, setOpenPopover] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const toggleDrawer = () => setOpen(!open);
  const isSignedIn = useSelector( state => state.app.isSignedIn );
  const prevIsSignedIn = usePrevious(isSignedIn);
  const isAdmin = useSelector( state => state.dashboard.isAdmin );
  const projects = useSelector( state => state.dashboard.projects );
  const reports = useSelector( state => state.dashboard.reports );
  const localTimeZone = useSelector( state => state.dashboard.localTimeZone );
  const projId = useSelector( state => state.dashboard.projId );
  const email = useSelector( state => state.dashboard.email );
  const dataLoadedDashboard = useSelector( state => state.dashboard.dataLoadedDashboard );
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleLogOff = async e => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      await firebase.auth().signOut(); // firebase.auth().onAuthStateChanged in App.js will handle the state change.
    } catch (err) {
      console.log(err.message);
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
    }
  };
  
  useEffect(() => {
    (async () => {
      // will execute when the user clicks log off button i.e. prevIsSignedIn is true
      if (prevIsSignedIn) {
        navigate('/signin');
        return;
      }
      
      // to cover the case that user put this page's address directly to the browser url bar; 
      // we give 3 seconds for firebase.initializeApp to finish and confirm if user is signed in.
      // if user is not signed in, we redirect the page to /signin
      if (!isSignedIn) {
        await delay(3000);
        if (!firebase.auth().currentUser) navigate('/signin');
        return;
      }
    })();
  }, [isSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (!isSignedIn) return;
    (async () => {
      try {
        await dispatch(Dashboard.getInitialData({
          userUid: firebase.auth().currentUser.uid,
          userClaimsPromise: firebase.auth().currentUser.getIdTokenResult().then(idTokenResult => idTokenResult.claims),
          localTimeZone,
        }));
      } catch (err) {
        setErrorMsg(err.message);
      } finally {
      }    
    })();
  }, [isSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps
      
  return (
    <Box sx={{ display: 'flex' }} >
      <CssBaseline />
      {/* drawer backdrop */}
      <Box 
        sx={{width: {position: 'absolute', xs: open? '100%' : '0px', sm: '0px'}, height: '100%', opacity: 0.5, backgroundColor: 'gray', zIndex: theme.zIndex.drawer - 1}}
        onClick={e => {
          if (matches && open) {
            setOpen(false);
          }
        }}
      />
      
      {/* appbar */}
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Dashboard
          </Typography>
          
          <Button variant="text" color="info" onClick={handleLogOff}>Log off</Button>
          
          <IconButton id="anchor-button" color="inherit" onClick={e => setOpenPopover(o => !o)}>
            <AccountCircleIcon />
          </IconButton>
          <Popover
            id="popover-account"
            open={openPopover}
            anchorEl={document.querySelector("button#anchor-button")}
            onClose={() => setOpenPopover(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography sx={{padding: '12px'}}>You signed in as {email}</Typography>
          </Popover>
          
        </Toolbar>
      </AppBar>
      
      {/* drawer */}
      <Drawer 
        variant="permanent"
        open={open}      
        onClick={e => {
          if (matches && open) {
            setTimeout(() => setOpen(false), 200);
          }
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          <ListItem button onClick={() => navigate('list')}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('settings')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          { isAdmin &&
          <ListItem button onClick={() => navigate('admin')}>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
          }
        </List>
        
        <Divider />
        <List>
          <ListSubheader inset>Projects</ListSubheader>            
          {
            projects.map((p, i) => (
              <ProjectItem key={p.id} project={p} currentProjId={projId}/>
            ))
          }
          <ListItem button sx={{}} onClick={e => navigate('project/payment')}>
            <ListItemIcon sx={{color: 'primary.main'}}>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Add project" />
          </ListItem>
        </List>

        <Divider />
        <List>
          <ListSubheader inset onClick={e => navigate('report/list')}>Reports</ListSubheader>            
          {
            reports.map((p, i) => (
              <ReportItem key={p.id} report={p} />
            ))
          }
          <ListItem button sx={{}} onClick={async e => {
            try{
              const { reportId, reportName } = await callServerPost('report-create');
              dispatch(changeInput({ name: 'reports', value: [...reports, { id: reportId, reportName }] }));
              navigate(`/dashboard/report/${reportId}`);         
            } catch(err) {
              setErrorMsg(err.message);
            }
          }}>
            <ListItemIcon sx={{color: 'primary.main'}}>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Add report" />
          </ListItem>
        </List>
        
        <br />

      </Drawer>
      
      {/* main content */}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          { dataLoadedDashboard && <Outlet /> }
          <br/>
          <br/>
          <Copyright/>
          <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        </Container>
      </Box>
    </Box>
  );
}

Dashboard.getInitialData = ({ userUid, userClaimsPromise, localTimeZone })=> async dispatch => {
  const [userSnapshot, userClaims, { result: _stateCounty }] = await Promise.all([ // fire three promises together to save time
    db.collection('users').doc(userUid).get(),
    userClaimsPromise,
    callServerGet('get-state-county', /*no auth required*/false),
  ]);
  
  batch(() => { // batch is necessary for React ver 17 or earlier; React ver 18 will automatically batch dispatch
    /* const userSnapshot = await db.collection('users').doc(firebase.auth().currentUser.uid).get(); */
    const user = userSnapshot.data();
    dispatch(changeProjects(user.projects ?? []));
    dispatch(changeInput({name: 'reports', value: user.reports ?? []}));
    dispatch(changeInput({name: 'firstName', value: user.firstName ?? ''}));
    dispatch(changeInput({name: 'middleInitial', value: user.middleInitial ?? ''}));
    dispatch(changeInput({name: 'lastName', value: user.lastName ?? ''}));
    dispatch(changeInput({name: 'address', value: user.address ?? ''}));
    dispatch(changeInput({name: 'phone', value: user.phone ?? ''}));
    dispatch(changeInput({name: 'isPhoneVerified', value: !!user.isPhoneVerified}));
    dispatch(changeInput({name: 'consentToDailySms', value: !!user.consentToDailySms}));
    dispatch(changeInput({name: 'consentToDailyEmail', value: !!user.consentToDailyEmail}));
    
    let _timeForText = (user.timeForText ?? 600) + minuteDiffBetweenTimeZone(user.localTimeZone, localTimeZone);
    if (_timeForText < 0) _timeForText += 24 * 60;
    if (_timeForText > 24 * 60) _timeForText -= 24 * 60;
    dispatch(changeInput({name: 'timeForText', value: _timeForText}));
    
    let _timeForEmail = (user.timeForEmail ?? 600) + minuteDiffBetweenTimeZone(user.localTimeZone, localTimeZone);
    if (_timeForEmail < 0) _timeForEmail += 24 * 60;
    if (_timeForEmail > 24 * 60) _timeForEmail -= 24 * 60;
    dispatch(changeInput({name: 'timeForEmail', value: _timeForEmail}));
    
    /* const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(); */
    dispatch(changeInput({name: 'email', value: userClaims.email}));
    dispatch(changeInput({name: 'isAdmin', value: !!userClaims.admin}));
    
    /* const { result: _stateCounty } = await callServerGet('get-state-county'); */
    dispatch(changeStateCounty(_stateCounty));
    const states = _stateCounty.map( s => s["STATE"] ).filter( (value, index, arr) => arr.indexOf(value) === index ); // get state list and remove duplicates
    dispatch(changeInput({name: 'states', value: states}));
    
    dispatch(changeInput({name: 'dataLoadedDashboard', value: true})); // data loaded successful; it will trigger child elements to start rendering
  });
}

export default Dashboard;
