import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import Slide from '@material-ui/core/Slide';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function MySnackbar(props) {
  const { severity, message, setMessage } = props;
  const [delayedMsg, setDelayedMsg] = useState();
  
  useEffect(() => {
    if (message.length !== 0) setDelayedMsg(message);
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = e => setMessage('');

  return (
      <Snackbar 
        open={message!==''} 
        autoHideDuration={3500} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={TransitionUp}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {delayedMsg}
        </Alert>
      </Snackbar>
      

  );
}