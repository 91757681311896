/*global isMobile globalParam*/
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/core/Alert';
/* import LockOutlinedIcon from '@material-ui/icons/LockOutlined'; */
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { states, tosItems } from '../features/Constants';
import { callServerGet, callServerPost } from '../features/CallServer';
import Copyright from './Copyright';
import { changeInput as changeSignUpInput } from './SignUp';
import Loading from '../features/Loading';
import MySnackbar from '../features/MySnackbar';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
function TosItem({ indexOfItem, itemSel, itemUpdateFunc, question, choices, errorMsg, validateFunc }) {
  const sel = itemSel[indexOfItem];
  return (
    <Grid item xs={12} sm={12}>
      <FormControl className="" sx={{ mb: 3, width: "100%" }}>
        <Typography component="h2" variant="h6">
          {question}
        </Typography>
        <Select
          value={sel}
          onChange={e => itemUpdateFunc(indexOfItem, Number(e.target.value))}
          native={isMobile}
          inputProps={{ 'aria-label': 'selection of tos' }}
          sx={{ mb: 1 }}
        >
          { choices.map( (c, i) => isMobile? <option key={i} value={i}>{c}</option> : <MenuItem key={i} value={i}>{c}</MenuItem> ) }
        </Select>
        
        { // show error message
          (() => {
            if (validateFunc(sel)) return;
            if (indexOfItem === 0){ // The first question "which state ..." has special format.
              return (
                <Alert severity="error">
                  Sorry, Disrobing Room does not currently support judge disqualification in the State of {choices[sel]}. If you are interested in bringing our service to {choices[sel]}, please <Link to="/contact">CONTACT US</Link>
                </Alert>
              );
            } else {
              return (
                <Alert severity="error">
                  {errorMsg}
                </Alert>
              );
            }
          })()
        }
      </FormControl>
    </Grid>
  );
}

export default function Tos(props) {
  const { firstName, middleInitial, lastName, email, address, phone, password, consentToNews } = useSelector( state => state.signup );
  const dispatch = useDispatch();
  const [itemSel, setItemSel] = useState([5, 0, 0, 0, 0, 0, 0, 0]);
  /* const [stateNames, setStateNames] = useState(states.map(s => s.name)); */
  const stateNames = useRef(states.map(s => s.name));
  const [validStates, setValidStates] = useState([]);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  
  useEffect(() => {
    /* setStateNames(states.map(s => s.name)); */
    
    (async () => {
      try {
        const { result: _stateCounty } = await callServerGet('get-state-county', /*no auth required*/false);
        const _validStates = _stateCounty.map( s => s["STATE"] ).filter( (value, index, arr) => arr.indexOf(value) === index ); // get state list and remove duplicates
        setValidStates(_validStates);
        setDataLoaded(true);
      } catch (err) {
        setErrorMsg(err.message);
      } finally {
      }
    })();
    
    
    return () => dispatch(changeSignUpInput({ name: 'password', value: '' }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const itemUpdateFunc = (indexOfItem, value) => {
    let i = [...itemSel];
    i[indexOfItem] = value;
    setItemSel(i);
  }

  const handleSignUp = async e => {
    e.preventDefault();
    e.target.disabled = true;
    setLoadingAccept(true);
    setSuccessMsg('');
    setErrorMsg('');
    try {
      const [a, ...b] = itemSel;
      if (validStates.indexOf(states[a].name) === -1 || b.some(c => c !== 0)) throw new Error('You need to provide the acceptable answers to all the above questions.');

      await callServerPost('create-user-2', {
        firstName,
        middleInitial,
        lastName,
        email,
        password,
        address,
        phone,
        consentToNews,
      });
      
      setSuccessMsg('An email has been sent to your email for verification. Check the junk folder too.');
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      setLoadingAccept(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar src={globalParam.logo100} sx={{ m: 1, bgcolor: 'secondary.main' }}>
          {/*<LockOutlinedIcon />*/}
        </Avatar>
        <Typography component="h1" variant="h5">
          Term of Service
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
          <Grid container spacing={2}>
            <TosItem
              indexOfItem={0}
              itemSel={itemSel}
              itemUpdateFunc={itemUpdateFunc}
              question="What State is your case in?"
              choices={stateNames.current}
              errorMsg
              validateFunc={sel => !dataLoaded || validStates.indexOf(states[sel].name) !== -1}
            >
            </TosItem>
            
            { tosItems.map((t, i) => (
                <TosItem
                  indexOfItem={i+1}
                  itemSel={itemSel}
                  itemUpdateFunc={itemUpdateFunc}
                  question={t.question}
                  choices={["Yes", "No"]}
                  errorMsg={t.errorMsg}
                  validateFunc={sel => sel === 0}
                  key={i}
                >
                </TosItem>
              ))
            }
            
          </Grid>
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleSignUp}>
            {loadingAccept? <Loading /> : 'Accept'}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/signin">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
            <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
            <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />  
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
      <br/>
    </Container>
  );
}
