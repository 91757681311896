/*global firebase, db, isMobile*/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MySnackbar from '../features/MySnackbar';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { changeInput, changeProjects } from '../pages/Dashboard';
import { callServerGet, callServerPost, callServerFormdata } from './CallServer';
import Loading from './Loading';


export function useReportSave() {
  const { 
    reports, reportId, reportName, report_reasonRemoveJudge, report_caseNumber, report_courthouseAddress, report_courthouseCity, report_courthouseZip, report_courthouseName, report_judgeName, state, report_county, 
    report_title, report_department, report_personalityJudge, report_externalLink1, report_nameExternalLink1, report_externalLink2, report_nameExternalLink2, report_startDateJudgeship, 
  } = useSelector( state => state.dashboard );
  const dispatch = useDispatch();
  
  return async e => {
    await callServerPost('report-save', { 
      reportId,
      reportName,
      reasonRemoveJudge: report_reasonRemoveJudge, 
      caseNumber: report_caseNumber, 
      courthouseAddress: report_courthouseAddress, 
      courthouseCity: report_courthouseCity, 
      courthouseZip: report_courthouseZip, 
      courthouseName: report_courthouseName, 
      judgeName: report_judgeName, 
      state, 
      county: report_county,      
      title: report_title, 
      department: report_department, 
      personalityJudge: report_personalityJudge, 
      externalLink1: report_externalLink1, 
      nameExternalLink1: report_nameExternalLink1, 
      externalLink2: report_externalLink2, 
      nameExternalLink2: report_nameExternalLink2, 
      startDateJudgeship: report_startDateJudgeship,
    });
    const index = reports.findIndex( p => p.id === reportId );
    let _reports = [...reports]; // because reports is immutable; any descenant of reports cannot be modified directly
    _reports[index] = {...reports[index]};
    _reports[index].reportName = reportName;
    dispatch(changeInput({ name: 'reports', value: _reports }));    
  }
}


export function useProjectSave() {
  const { 
    projId, projects,
    projName, reasonRemoveJudge, caseNumber, courthouseAddress, courthouseCity, courthouseZip, courthouseName, judgeName, userConfirm, state, county,
    serverFirstName, serverMiddleInitial, serverLastName, serverAddress, serverCity, serverState, serverZip, methodOfService, isPresidingJudge, presJudgeName, presJudgeState, presJudgeCounty, presJudgeCourthouseName, presJudgeCourthouseAddress, isJudgeServed, dateOfService, timeOfService, dateOfProof, cityOfProof, stateOfProof,
    isNoticeOfIntentionFiled, dateNoticeOfIntentionFiled, confirmDateNoticeOfIntentionFiled,
    reasonRecuseJudge,
    nameOfTopParty, designationOfTopParty, nameOfBottomParty, designationOfBottomParty, isAttorney, attorneyName, attorneyAddress, attorneyCity, attorneyState, attorneyZip,
  } = useSelector( state => state.dashboard );
  const dispatch = useDispatch();
  
  return async e => {
    await callServerPost('save-project', {
      projId, 
      projName, reasonRemoveJudge, caseNumber, courthouseAddress, courthouseCity, courthouseZip, courthouseName, judgeName, userConfirm, state, county,
      serverFirstName, serverMiddleInitial, serverLastName, serverAddress, serverCity, serverState, serverZip, methodOfService, isPresidingJudge, presJudgeName, presJudgeState, presJudgeCounty, presJudgeCourthouseName, presJudgeCourthouseAddress, isJudgeServed, dateOfService, timeOfService, dateOfProof, cityOfProof, stateOfProof, projLocalTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      isNoticeOfIntentionFiled, dateNoticeOfIntentionFiled, confirmDateNoticeOfIntentionFiled,
      reasonRecuseJudge,
      nameOfTopParty, designationOfTopParty, nameOfBottomParty, designationOfBottomParty, isAttorney, attorneyName, attorneyAddress, attorneyCity, attorneyState, attorneyZip,
    });
    
    // update projName if changed
    const index = projects.findIndex( p => p.id === projId );
    if (projects[index].projName !== projName){
      let _projects = [...projects]; // because projects is immutable; any descenant of projects cannot be modified directly
      _projects[index] = {...projects[index]};
      _projects[index].projName = projName;
      dispatch(changeProjects(_projects));
    }
  };
}

export function ProjSaveButton(){
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const projectSave = useProjectSave();
  
  const handleSave = async e => {
    e.preventDefault();
    e.target.disabled = true;
    setLoading(true);
    try {
      await projectSave();
      setSuccessMsg('Successfully saved');
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      setLoading(false);
    }
  };
  
  return (
    <>
      <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={handleSave}>
        {loading? <Loading /> : 'Save'}
      </Button>
      <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
      <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />
    </>
  )
}

export function PdfDownloadButton({projId, docType, downloadFileName, buttonText}){
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const projectSave = useProjectSave();  

  const handleDownload = async e => {
    e.target.disabled = true;
    setLoading(true);
    try {
      // save project first
      await projectSave();
      
      // download pdf
      const resultBlob = await callServerGet(`download-pdf/${projId}/${docType}`);
      const url = window.URL.createObjectURL(resultBlob);
      const link = document.querySelector(`a#${docType}-download`);
      link.href = url;
      link.setAttribute('download', downloadFileName);
      link.click();
    } catch (err) {
      console.log(err);
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      setLoading(false);
    }
  }
  
  return (
    <>
      <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={handleDownload}>
        {loading? <Loading /> : buttonText}
      </Button>
      <a id={`${docType}-download`} href='/#' style={{display:'none'}}>download</a>
      <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
      <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />
    </>
  )
}




export function PdfDownloadUpload({ description, fileName, keyDocId, keyDocName, keyDocUrl }) {
  const projId = useSelector( state => state.dashboard.projId );
  const dashboard = useSelector( state => state.dashboard );
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();
  const tagId = fileName.toLowerCase().replace(/[^A-Za-z0-9]/g, '-');
  
  useEffect(() => {
    document.querySelector(`input#${tagId}-input`).addEventListener("click", () => document.querySelector(`form#${tagId}-form`).reset(), false);
    document.querySelector(`input#${tagId}-input`).addEventListener("change", handleFiles, false);
    async function handleFiles() {
      try{
        const { fileDocId, downloadUrl } = await callServerFormdata('upload-project-file', {
          projId, 
          keyDocId,
          keyDocName,
          keyDocUrl,
          contentType: 'application/pdf',
          fileName,
        }, this.files);
        
        dispatch(changeInput({ name: keyDocId, value: fileDocId }));
        dispatch(changeInput({ name: keyDocName, value: fileName }));
        dispatch(changeInput({ name: keyDocUrl, value: downloadUrl }));
        setSuccessMsg('File uploaded.');
      } catch(err) {
        setErrorMsg(err.message);
      } finally {
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownload = async e => {
    try {
      const res = await fetch(dashboard[keyDocUrl], {
        method: "GET",
      });
      const resultBlob = await res.blob();
      const url = window.URL.createObjectURL(resultBlob);
      const link = document.querySelector(`a#${tagId}-download`);
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
    } catch(err) {
      setErrorMsg(err.message);
    }
  }
  
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Typography component="h2" variant="body1">
          {description}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <form id={`${tagId}-form`} style={{display:'none'}}>
          <input type="file" id={`${tagId}-input`} />
        </form>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={e => document.querySelector(`input#${tagId}-input`).click()}>
          Upload
        </Button>
      </Grid>
      { dashboard[keyDocName] &&
        <> 
          <Grid item xs={6} sm={6}>
            <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={handleDownload}>
              Download
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography component="h2" variant="body1" sx={{ color: 'primary.main', opacity: 0.8, }}>
              Uploaded file: {dashboard[keyDocName]}
            </Typography>
          </Grid>     
        </>
      }
      <a id={`${tagId}-download`} href='/#' style={{display:'none'}}>download</a>
      <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
      <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />      
    </>
  )
}


export function ProjToReportButton({ projId }){
  const { reports } = useSelector( state => state.dashboard );
  const [reportId, setReportId] = useState('');
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const projectSave = useProjectSave();
  
  useEffect(() => {
    setReportId(reports[0]?.id ?? 0);
  }, [reports]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleCopy = async e => {
    e.preventDefault();
    e.target.disabled = true;
    setLoading(true);
    try {
      await projectSave();
      
      await callServerPost('copy-project-to-report', {
        projId, 
        reportId,
      });
      
      const userUid = firebase.auth().currentUser.uid;
      const userSnapshot = await db.collection('users').doc(userUid).get();
      dispatch(changeInput({name: 'reports', value: userSnapshot.get('reports') ?? []}));
      
      setSuccessMsg('Successfully copied');
      setDialogOpen(false);
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      setLoading(false);
    }
  };
  
  return (
    <>
      <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={e => setDialogOpen(true)}>
        Copy to report
      </Button>
      <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
      <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />

      <Dialog open={dialogOpen} onClose={e => setDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Copy project to report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the target report to copy to.
          </DialogContentText>
          <Select
            labelId="select-report"
            value={reportId}
            onChange={e => setReportId(e.target.value)}
            native={isMobile}
            inputProps={{ 'aria-label': 'selection of report' }}
            sx={{ mt: 1, mb: 1 }}
          >
            { reports.map( (c, i) => isMobile? <option key={i} value={c.id}>{c.reportName}</option> : <MenuItem key={i} value={c.id}>{c.reportName}</MenuItem> ) }
            { isMobile? <option key={-1} value={0}>Create new report</option> : <MenuItem key={-1} value={0}>Create new report</MenuItem> }
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={handleCopy}>
            {loading? <Loading /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export function ReportToProjButton({ reportId }){
  const { projects } = useSelector( state => state.dashboard );
  const [projId, setProjId] = useState('');
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();
  const reportSave = useReportSave();

  useEffect(() => {
    setProjId(projects[0]?.id ?? '');
  }, [projects]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleCopy = async e => {
    e.preventDefault();
    e.target.disabled = true;
    setLoading(true);
    try {
      await reportSave();      
      await callServerPost('copy-report-to-project', {
        projId, 
        reportId,
      });
            
      const userUid = firebase.auth().currentUser.uid;
      const userSnapshot = await db.collection('users').doc(userUid).get();
      dispatch(changeProjects(userSnapshot.get('projects') ?? []));
      
      setSuccessMsg('Successfully copied');
      setDialogOpen(false);
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      setLoading(false);
    }
  };
  
  return (
    <>
      <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={e => {
        if (projects?.length > 0){
          setDialogOpen(true);
        } else {
          setErrorMsg('You need to have at least one project.');
        }
      }}>
        Copy to project
      </Button>
      <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
      <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />

      <Dialog open={dialogOpen} onClose={e => setDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Copy report to project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the target project to copy to.
          </DialogContentText>
          <Select
            labelId="select-project"
            value={projId}
            onChange={e => setProjId(e.target.value)}
            native={isMobile}
            inputProps={{ 'aria-label': 'selection of project' }}
            sx={{ mt: 1, mb: 1 }}
          >
            { projects.map( (c, i) => isMobile? <option key={i} value={c.id}>{c.projName}</option> : <MenuItem key={i} value={c.id}>{c.projName}</MenuItem> ) }
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={handleCopy}>
            {loading? <Loading /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}