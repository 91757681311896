/*global firebase globalParam*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/core/Alert';
/* import LockOutlinedIcon from '@material-ui/icons/LockOutlined'; */
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Copyright from './Copyright';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

const thisSlice = createSlice({
  name: 'resetpassword',
  initialState: { email: '' },
  reducers: {
    changeInput: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
  }
});
const { changeInput } = thisSlice.actions;
export const reducer = thisSlice.reducer;


/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function ResetPassword() {
  const { email } = useSelector( state => state.resetpassword );
  const dispatch = useDispatch();
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState(''); 

  const handleChangeText = e => dispatch(changeInput({ name: e.target.getAttribute('name'), value: e.target.value }));
  
  const handleSubmit = async e => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      const emailChecker = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!emailChecker.test(email)) throw new Error('Invalid email.');
      await firebase.auth().sendPasswordResetEmail(email);
      setSuccessMsg(`The instruction to reset password has been sent to ${email}. Check the junk folder too.`);
    } catch(err){
      setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar src={globalParam.logo100} sx={{ m: 1, bgcolor: 'secondary.main' }}>
          {/*<LockOutlinedIcon />*/}
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <Typography component="h2" variant="body1">
          The instruction will be sent to your email.
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleChangeText}
          />

          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleSubmit}>
            Send
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/signin">
                Wait! I remember now
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup">
                {"Sign Up"}
              </Link>
            </Grid>
          </Grid>
          {(successMsg!=='') && <Alert severity="success">{successMsg}</Alert>}
          {(errorMsg!=='') && <Alert severity="error">{errorMsg}</Alert>}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
