/*global */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { callServerPost } from '../features/CallServer';
import MySnackbar from '../features/MySnackbar';
import { changeProjects } from '../pages/Dashboard';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
export default function ProjectCreate() {
  const dispatch = useDispatch();
  const projects = useSelector( state => state.dashboard.projects );
  const [projId, setProjId] = useState();
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  
  useEffect(() => {
    (async () => {
      try {
        const { id, projName } = await callServerPost('create-project');
        dispatch(changeProjects([...projects, { id, projName }]));
        setProjId(id);
      } catch (err) {
        setErrorMsg(err.message);
      } finally {
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h2" variant="h6">
          {projId? 'Project created successful.' : 'Thank you for your purchase. We are creating your new project...'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        { projId &&
          <Link to={`/dashboard/project/${projId}/11`}>
            Go to the new project
          </Link>
        }
      </Grid>
      <Grid item>
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />      
      </Grid>
      
    </Grid>
  );
}
