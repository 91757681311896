import { useSelector, useDispatch } from 'react-redux';
import { changeProjects } from '../pages/Dashboard';
import { callServerPost } from './CallServer';

export default function useProjectSave({setLoadingSave, setSuccessMsg, setErrorMsg}) {
  const { 
    projId, projects,
    projName, reasonRemoveJudge, caseNumber, courthouseAddress, courthouseName, judgeName, userConfirm, state, county,
    serverFirstName, serverMiddleInitial, serverLastName, serverAddress, methodOfService, isPresidingJudge, presJudgeName, presJudgeState, presJudgeCounty, presJudgeCourthouseName, presJudgeCourthouseAddress, isJudgeServed, dateOfService, timeOfService,
    isNoticeOfIntentionFiled, dateNoticeOfIntentionFiled, confirmDateNoticeOfIntentionFiled,    
    reasonRecuseJudge,
  } = useSelector( state => state.dashboard );
  const dispatch = useDispatch();
  
  const handleSave = async e => {
    e.preventDefault();
    e.target.disabled = true;
    if (setLoadingSave) setLoadingSave(true);
    try {
      await callServerPost('save-project', {
        projId, 
        projName, reasonRemoveJudge, caseNumber, courthouseAddress, courthouseName, judgeName, userConfirm, state, county,
        serverFirstName, serverMiddleInitial, serverLastName, serverAddress, methodOfService, isPresidingJudge, presJudgeName, presJudgeState, presJudgeCounty, presJudgeCourthouseName, presJudgeCourthouseAddress, isJudgeServed, dateOfService, timeOfService, projLocalTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isNoticeOfIntentionFiled, dateNoticeOfIntentionFiled, confirmDateNoticeOfIntentionFiled,    
        reasonRecuseJudge,
      });
      
      // update projName if changed
      const index = projects.findIndex( p => p.id === projId );
      if (projects[index].projName !== projName){
        let _projects = [...projects]; // because projects is immutable; any descenant of projects cannot be modified directly
        _projects[index] = {...projects[index]};
        _projects[index].projName = projName;
        dispatch(changeProjects(_projects));
      }
      
      if (setSuccessMsg) setSuccessMsg('Successfully saved');
    } catch (err) {
      if (setErrorMsg) setErrorMsg(err.message);
    } finally {
      e.target.disabled = false;
      if (setLoadingSave) setLoadingSave(false);
    }
  };
  return handleSave;
}