/*global */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // load on demand
import timezone from 'dayjs/plugin/timezone' // load on demand
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Loading from '../features/Loading';
import useProjectSave from '../features/useProjectSave';
import MySnackbar from '../features/MySnackbar';
import { changeInput } from '../pages/Dashboard';


/*****************************************************************************************
* set up redux for this component
*****************************************************************************************/
import { useSelector, useDispatch } from 'react-redux';


/*****************************************************************************************
* component definition
*****************************************************************************************/
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ProjectStep50() {
  const { localTimeZone, projId, userConfirm, dateOfService } = useSelector( state => state.dashboard );
  const [filingDeadline, setFilingDeadline] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStep = 4;
  const handleSave = useProjectSave({setLoadingSave, setSuccessMsg, setErrorMsg});

  useEffect(() => {
    dispatch(changeInput({name: 'activeStep', value: currentStep})); // to handle navigation to this page through direct url
  }, []); // eslint-disable-line react-hooks/exhaustive-deps  
  
  useEffect(() => {    
    if (dateOfService){
      const t = dayjs(dateOfService).tz(localTimeZone).add(7, 'day');
      setFilingDeadline(t.format('MMMM DD, YYYY'));
    }
  }, [dateOfService, localTimeZone]); // eslint-disable-line react-hooks/exhaustive-deps  
  
  const handleChangeCheckBox = e => {
    let _userConfirm = [...userConfirm];
    _userConfirm[currentStep] = e.target.checked;
    dispatch(changeInput({ name: 'userConfirm', value: _userConfirm }));
  }
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="subtitle1">
          Step 5: URGENT REMINDER OF FILING DEADLINE
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="body1" sx={{marginBottom: '12px',}}>
          URGENT: The Notice of Intention and the Proof of Service must be filed on or before <b>{filingDeadline}</b>. Complete the Proof of Service, then File the Notice of Intention and the Proof of Service ASAP.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Checkbox value="userConfirm" color="primary" checked={userConfirm[currentStep] ?? false}
          onChange={handleChangeCheckBox} />}
          label="I confirm I am aware of the filing deadline."
          name="userConfirm"
        />
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep - 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 10}`);
          }}
        >
          Prev
        </Button>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button variant="contained" sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} onClick={handleSave}>
          {loadingSave? <Loading /> : 'Save'}
        </Button>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Button 
          variant="contained" 
          sx={{ mt: 1, mb: 2, minWidth: {xs: '80%', sm: '40%'} }} 
          onClick={e => {
            dispatch(changeInput({name: 'activeStep', value: currentStep + 1}));
            navigate(`/dashboard/project/${projId}/${currentStep + 12}`);
          }}
        >
          Next
        </Button>
      </Grid>
      <Grid item>
        <MySnackbar severity='error' message={errorMsg} setMessage={setErrorMsg} />
        <MySnackbar severity='success' message={successMsg} setMessage={setSuccessMsg} />      
      </Grid>
      
    </Grid>
  );
}
